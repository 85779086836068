import { getDailyLoser } from "@services/alert/daily-loser";
import common from "@assets/js/common.js";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -274.4,
            "profitClosedMT4": -1555.8,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938645,
            "user": "Demo:MT5-Demo:xxxxxx83",
            "server_name": "MT5-Demo",
            "login": "xxxxxx83",
            "closed_profit_eod": -274.4,
            "closed_profit": -1555.8
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -182,
            "profitClosedMT4": -961.5,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938618,
            "user": "Demo:MT5-Demo:xxxxxx58",
            "server_name": "MT5-Demo",
            "login": "xxxxxx58",
            "closed_profit_eod": -182,
            "closed_profit": -961.5
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -152.2,
            "profitClosedMT4": -994.7,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938608,
            "user": "Demo:MT5-Demo:xxxxxx66",
            "server_name": "MT5-Demo",
            "login": "xxxxxx66",
            "closed_profit_eod": -152.2,
            "closed_profit": -994.7
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -823.7,
            "profitClosedMT4": -1048.6,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938645,
            "user": "Demo:MT5-Demo:xxxxxx17",
            "server_name": "MT5-Demo",
            "login": "xxxxxx17",
            "closed_profit_eod": -823.7,
            "closed_profit": -1048.6
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -162,
            "profitClosedMT4": -354,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938630,
            "user": "Demo:MT5-Demo:xxxxxx06",
            "server_name": "MT5-Demo",
            "login": "xxxxxx06",
            "closed_profit_eod": -162,
            "closed_profit": -354
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -128,
            "profitClosedMT4": -163,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938624,
            "user": "Demo:MT5-Demo:xxxxxx73",
            "server_name": "MT5-Demo",
            "login": "xxxxxx73",
            "closed_profit_eod": -128,
            "closed_profit": -163
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -117,
            "profitClosedMT4": 17.1,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938659,
            "user": "Demo:MT5-Demo:xxxxxx02",
            "server_name": "MT5-Demo",
            "login": "xxxxxx02",
            "closed_profit_eod": -117,
            "closed_profit": 17.1
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -332.3,
            "profitClosedMT4": -368.6,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938659,
            "user": "Demo:MT5-Demo:xxxxxx45",
            "server_name": "MT5-Demo",
            "login": "xxxxxx45",
            "closed_profit_eod": -332.3,
            "closed_profit": -368.6
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -562.66,
            "profitClosedMT4": -562.66,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938634,
            "user": "Demo:MT5-Demo:xxxxxx56",
            "server_name": "MT5-Demo",
            "login": "xxxxxx56",
            "closed_profit_eod": -562.66,
            "closed_profit": -562.66
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -107.15,
            "profitClosedMT4": -2945.84,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938630,
            "user": "Demo:MT5-Demo:xxxxxx55",
            "server_name": "MT5-Demo",
            "login": "xxxxxx55",
            "closed_profit_eod": -107.15,
            "closed_profit": -2945.84
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -120.77,
            "profitClosedMT4": 15.1,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938659,
            "user": "Demo:MT5-Demo:xxxxxx70",
            "server_name": "MT5-Demo",
            "login": "xxxxxx70",
            "closed_profit_eod": -120.77,
            "closed_profit": 15.1
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -1456.55,
            "profitClosedMT4": -391.67,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938630,
            "user": "Demo:MT5-Demo:xxxxxx46",
            "server_name": "MT5-Demo",
            "login": "xxxxxx46",
            "closed_profit_eod": -1456.55,
            "closed_profit": -391.67
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": -118.21,
            "profitClosedMT4": 391.85,
            "recordDate": "2023-08-25",
            "threshold": -100,
            "timestamp": 1692938654,
            "user": "Demo:MT5-Demo:xxxxxx15",
            "server_name": "MT5-Demo",
            "login": "xxxxxx15",
            "closed_profit_eod": -118.21,
            "closed_profit": 391.85
        }
    ],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Closed Profit EOD",
            value: "closed_profit_eod",
            align: "right",
        },
        // {
        //     text: "Closed Profit MT",
        //     value: "closed_profit",
        //     align: "right",
        // },
    ],
    csvHeader: {
        "Server Name": "server_name",
        Login: "login",
        Books: "books",
        "Closed Profit EOD": "closed_profit_eod",
        "Closed Profit MT": "closed_profit",
    },
    selectedDate: null,
};

const actions = {
    getDailyLoserAction({ commit }, data) {
        if (common.getMT4TimeString().split(" ")[0] !== state.selectedDate) {
            commit("UPDATE_LOADING", true);
            getDailyLoser(data).then((res) => {
                res.data.forEach((item) => {
                    item["books"] = item.books.replace(/[[\]]/g, "");
                });
                commit("UPDATE_ALERT_DATA", res.data);
                commit("UPDATE_LOADING", false);
            });
        }
    },
    processDailyLoser({ commit }, data) {
        if (common.getMT4TimeString().split(" ")[0] === state.selectedDate) {
            commit("UPDATE_LOADING", true);
            let result = [];
            for (let item in data) {
                if (item.includes("--loser")) {
                    let temp = data[item];
                    temp.books = temp.books.join(",");
                    temp.server_name = temp.user.split(":")[1];
                    temp.login = temp.user.split(":")[2];
                    temp.closed_profit_eod = temp.profitClosed;
                    temp.closed_profit = temp.profitClosedMT4;
                    result.push(temp);
                }
            }
            commit("UPDATE_ALERT_DATA", result);
            commit("UPDATE_LOADING", false);
        }
    },
};

const mutations = {
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
