
export function login(query) {
    const mockData = {
        status: 200,
        data: {
            "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY5Mjg1NTk0MywianRpIjoiOGMyNTFlZDQtNGYxNC00MjIyLThmOWItNGViY2FmYTNlMzQwIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InRlc3QudGVzdEBzaWdtYXRtLmNvbS5hdT8yMT9VQVQiLCJuYmYiOjE2OTI4NTU5NDMsImV4cCI6MTY5MzM5NTk0M30.zfxRR35cy7gzSf9hv6d2GdY58-OV-4b8Le8vHweN73w",
            "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY5Mjg1NTk0MywianRpIjoiNjFiYWJiZTQtZmNmNy00YjRiLWI4OTYtMGU2MjkyZjEwZTQ3IiwidHlwZSI6InJlZnJlc2giLCJzdWIiOiJ0ZXN0LnRlc3RAc2lnbWF0bS5jb20uYXUiLCJuYmYiOjE2OTI4NTU5NDMsImV4cCI6MTY5NTQ0Nzk0M30.aowC9RgXxpQzEkWInaoC6ssEfPuAxF8Q4rSsGpyvS60",
            "permission": {
                "broker": [],
                "frontPermission": {
                    "book": {
                        "child": [],
                        "function": [
                            "homeLoginDetailSummary",
                            "extraSymbolDetails",
                            "dailySummaryIcon",
                            "mtdVolumeColumn",
                            "mtdPnlColumn",
                            "loginSummaryInfo",
                            "loginSummaryStatistic",
                            "loginSummaryTrades"
                        ]
                    },
                    "lp": {
                        "child": []
                    },
                    "alert": {
                        "child": [
                            "overview"
                        ]
                    },
                    "settings": {
                        "child": [
                            "alertConfig",
                            "brokerConfig",
                            "serverConfig",
                            "bookConfig",
                            "pkTaker",
                            "routingConfig",
                            "copyTradeConfig",
                            "lpConfig",
                            "notificationConfig"
                        ],
                        "function": [
                            "alertConfigEdit"
                        ]
                    },
                    "system": {
                        "child": [
                            "group",
                            "bookGroupRules",
                            "userPerformance",
                            "snapshot",
                            "symbolRule",
                            "tick",
                            "ticklive",
                            "alertConfiguration",
                            "notification"
                        ]
                    },
                    "account": {
                        "child": [
                        ]
                    },
                    "risk": {
                        "child": [
                            "prometheus",
                            "notification"
                        ],
                        "function": []
                    }
                },
                "backendPermission": "*",
                "allowJsonEditor": 1,
                "whitelist": [
                    "*"
                ],
                "blocklist": []
            },
            "username": "demo.demo",
            "home_page_profile_id": 23,
            "alert_page_profile_id": 22
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function logUserOut(query){
    const mockData = {
        status: 200,
        data: { 'msg': 'Log user out' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}