<template>
    <v-app id="inspire">
        <v-app-bar app color="#1A3958" dark height="40px">
            <div>
                <v-img src="@assets/logo_icon.png" width="25"></v-img>
            </div>

            <v-toolbar-title class="ml-4 mr-4" style="min-width: 140px"
                >Position Keeper</v-toolbar-title
            >

            <p
                class="mt-0 mb-0"
                v-show="showMenuButton"
                @click="() => moveDiv(-1)"
            >
                <v-icon :color="disableToMoveLeft ? 'grey' : 'white'"
                    >mdi-menu-left</v-icon
                >
            </p>
            <div class="scrollmenu" id="timeDiv">
                <CurrentLiveTime :name="'NY'" :DST="true" />
                <CurrentLiveTime :name="'LDN'" :DST="false" />
                <CurrentLiveTime :name="'MT'" :DST="false" />
                <CurrentLiveTime :name="'JKT'" :DST="true" />
                <CurrentLiveTime :name="'BJ'" :DST="true" />
                <CurrentLiveTime :name="'SYD'" :DST="true" />
            </div>
            <v-spacer></v-spacer>
            <p
                class="mt-0 mb-0"
                v-show="showMenuButton"
                @click="() => moveDiv(1)"
            >
                <v-icon :color="disableToMoveRight ? 'grey' : 'white'"
                    >mdi-menu-right</v-icon
                >
            </p>

            <v-tooltip
                bottom
                content-class="pumpingStatusTooltip"
                color="rgba(97, 97, 97, 1)"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        :color="
                            pumpingStatus === 'success'
                                ? 'green'
                                : pumpingStatus === 'fail'
                                ? 'red'
                                : 'orange'
                        "
                        class="mr-1"
                        >mdi-list-status</v-icon
                    >
                </template>
                <div v-for="listItem in pumpingStatusList">
                    <span>{{ listItem.server }}: </span>
                    <v-icon x-small :color="listItem.alive ? 'green' : 'red'"
                        >mdi-circle</v-icon
                    >
                </div>
            </v-tooltip>
            <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        color="orange"
                        class="mr-2"
                        @click="holidayDialog = true"
                        >mdi-calendar-weekend</v-icon
                    >
                </template>
                <span>Click to see holiday information</span>
            </v-tooltip> -->
            <v-menu
                offset-y
                :nudge-top="0"
                open-on-hover
                :close-on-content-click="false"
                tile
                z-index="1000000"
            >
                <template v-slot:activator="{ on, attrs }">
                    <a
                        v-bind="attrs"
                        v-on="on"
                        style="color: white; min-width: 80px"
                        class="mr-20"
                    >
                        Hi, {{ displayName }}
                    </a>
                </template>
                <v-list
                    class="pl-0 pr-0 pt-0 pb-0"
                    style="text-align: left; border-radius: 0px"
                >
                    <v-list-item
                        dense
                        to="/account/permission"
                        v-if="loadPermission == 1"
                    >
                        <v-list-item-icon class="mr-0"
                            ><v-icon small>mdi-shield</v-icon></v-list-item-icon
                        >
                        <v-list-item-title>Permission</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        dense
                        to="/account/management"
                        v-if="loadManagement == 1"
                    >
                        <v-list-item-icon class="mr-0"
                            ><v-icon small
                                >mdi-folder-multiple</v-icon
                            ></v-list-item-icon
                        >
                        <v-list-item-title>Management</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        dense
                        to="/system/settings"
                        v-if="loadSettings == 1"
                    >
                        <v-list-item-icon class="mr-0"
                            ><v-icon small
                                >mdi-cog-outline</v-icon
                            ></v-list-item-icon
                        >
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        dense
                        to="/account/security"
                        v-if="loadSecurity == 1"
                    >
                        <v-list-item-icon class="mr-0"
                            ><v-icon small
                                >mdi-shield-account</v-icon
                            ></v-list-item-icon
                        >
                        <v-list-item-title>Security</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item dense @click.stop="logout()">
                        <v-list-item-icon class="mr-0"
                            ><v-icon small>mdi-logout</v-icon></v-list-item-icon
                        >
                        <v-list-item-title
                            ><v-btn
                                text
                                :loading="logoutLoading"
                                @click.prevent
                                class="pl-0 pr-0"
                                >Logout</v-btn
                            ></v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main class="pt-0">
            <v-container fluid id="global-container">
                <HeaderMenu />

                <router-view></router-view>

                <div class="text-center">
                    <v-overlay :value="this.$store.state.overlay">
                        <v-progress-circular
                            indeterminate
                            :size="70"
                            :width="7"
                            color="primary"
                        ></v-progress-circular>
                        <p>Loading Data...</p>
                    </v-overlay>
                </div>
            </v-container>
            <v-dialog
                v-model="holidayDialog"
                width="1000"
                :persistent="true"
                style="overflow: hidden"
            >
                <v-card class="pt-0">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            Holiday Information
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="white"
                            icon
                            @click="holidayDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <v-row>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Server:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedServer"
                                    :items="serverOptions"
                                    item-text="server_name"
                                    item-value="id"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Symbol:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedSymbol"
                                    :items="symbolOptions"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Show History:
                                </div></v-col
                            >
                            <v-col cols="2"
                                ><v-switch
                                    v-model="showHistory"
                                    dense
                                    hide-details
                                ></v-switch
                            ></v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Year:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedYear"
                                    :items="yearOptions"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Month:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedMonth"
                                    :items="MonthOptions"
                                    item-text="text"
                                    item-value="value"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="1"
                                ><div
                                    style="
                                        display: flex;
                                        align-items: end;
                                        justify-content: center;
                                        height: 100%;
                                    "
                                >
                                    Day:
                                </div></v-col
                            ><v-col cols="3">
                                <v-autocomplete
                                    single-line
                                    v-model="selectedDay"
                                    :items="dayOptions"
                                    clearable
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12"
                                ><v-data-table
                                    dense
                                    :headers="holidayTableHeader"
                                    :items="holidayTableData"
                                    :header-props="{ sortIcon: null }"
                                    item-key="id"
                                    class="elevation-0 flex-grow-1"
                                    :items-per-page="25"
                                    id="home-table"
                                    fixed-header
                                >
                                    <template
                                        v-slot:item.server_id="{ item }"
                                        >{{
                                            serverOptions.find(
                                                (data) =>
                                                    data.id === item.server_id
                                            ).server_name
                                        }}</template
                                    >
                                    <template
                                        v-slot:item.description="{ item }"
                                    >
                                        <div
                                            v-if="item.description.length < 53"
                                        >
                                            {{ item.description }}
                                        </div>
                                        <v-tooltip top v-else>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <div v-bind="attrs" v-on="on">
                                                    {{
                                                        item.description.slice(
                                                            0,
                                                            53
                                                        )
                                                    }}...
                                                </div>
                                            </template>
                                            <span>{{ item.description }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table></v-col
                            >
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
import HeaderMenu from "@components/HeaderMenu";
import CurrentLiveTime from "@components/ComponentCurrentLiveTime";
import { permission } from "@components/mixins/permission";
import { putUsers } from "@services/account/user";
import { logUserOut } from "@services/account/auth";
import { getHolidayData } from "@services/holiday";
import dayjs from "dayjs";
import deepClone from "deep-clone";
import { mapState, mapActions } from "vuex";
import { getPumpingStatus } from "@services/pumping-status";
import { snackbar } from "@components/mixins/snackbar";

var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

export default {
    name: "Layout",
    components: { CurrentLiveTime, HeaderMenu },
    mixins: [permission, snackbar],
    data: () => ({
        pumpingStatus: "success",
        pumpingStatusTimer: null,
        pumpingStatusList: [],

        loadManagement: 0,
        loadSecurity: 0,
        loadSettings: 0,
        loadPermission: 0,
        loadDailySummaryIcon: 0,
        drawer: false,
        alertDrawer: false,
        channelList: [],
        consoleDrawer: false,
        selectedAlertPanel: 1,
        loadRing: true,
        profileUpdate: {
            home: false,
            alert: false,
        },
        logoutLoading: false,

        holidayDialog: false,
        holidayTableHeader: [
            { text: "Server", value: "server_id", width: 30, align: "left" },
            { text: "Symbol", value: "symbol", width: 60, align: "left" },
            { text: "Date", value: "date", width: 90, align: "left" },
            {
                text: "Description",
                value: "description",
                width: 200,
                align: "left",
            },
        ],
        holidayData: [],
        dayOfWeek: {
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday",
            7: "Sunday",
        },
        serverOptions: [],
        symbolOptions: [],
        yearOptions: [],
        MonthOptions: [
            { text: "January", value: 1 },
            { text: "February", value: 2 },
            { text: "March", value: 3 },
            { text: "April", value: 4 },
            { text: "May", value: 5 },
            { text: "June", value: 6 },
            { text: "July", value: 7 },
            { text: "August", value: 8 },
            { text: "September", value: 9 },
            { text: "October", value: 10 },
            { text: "November", value: 11 },
            { text: "December", value: 12 },
        ],
        dayOptions: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ],
        selectedServer: null,
        selectedSymbol: null,
        selectedYear: null,
        selectedMonth: null,
        selectedDay: null,
        showHistory: false,
        screenWidth: 0,
        menuWidth: 0,
        showMenuButton: false,
        disableToMoveLeft: false,
        disableToMoveRight: true,
    }),
    computed: {
        ...mapState("SettingsServer", ["serverData"]),
        /**
         * Display username
         * @return  {[type]}  [return description]
         */
        displayName() {
            return localStorage.getItem("displayName");
        },
        holidayTableData() {
            let returnValue = deepClone(this.holidayData);
            if (this.selectedServer) {
                returnValue = returnValue.filter(
                    (item) => item.server_id === this.selectedServer
                );
            }
            if (this.selectedSymbol) {
                returnValue = returnValue.filter(
                    (item) => item.symbol === this.selectedSymbol
                );
            }
            if (this.selectedYear) {
                returnValue = returnValue.filter(
                    (item) => item.year === this.selectedYear
                );
            }
            if (this.selectedMonth) {
                returnValue = returnValue.filter(
                    (item) => item.month === this.selectedMonth
                );
            }
            if (this.selectedDay) {
                returnValue = returnValue.filter(
                    (item) => item.day === this.selectedDay
                );
            }
            if (!this.showHistory) {
                const today = dayjs(dayjs().format("YYYY-MM-DD")).unix();
                returnValue = returnValue.filter(
                    (item) =>
                        dayjs(
                            item.year + "-" + item.month + "-" + item.day
                        ).unix() >= today
                );
            }
            return returnValue;
        },
    },
    watch: {
        profileUpdate(nv) {
            if (nv.alert && nv.home) {
                this.$router.push({ name: "Signin" });
                this.$store.state.loginStatus = -1;
                logUserOut({ id: 0 }).then(() => {
                    localStorage.clear();
                });
            }
        },
        serverData(nv) {
            this.serverOptions = nv;
        },
    },
    methods: {
        ...mapActions("SettingsServer", ["getServerAction"]),
        ...mapActions("Home", ["getSymbolDPAction"]),
        /**
         * Logout user account
         * @return  {[type]}  [return description]
         */
        logout() {
            this.logoutLoading = true;
            // this.updateAlertProfile();
            // this.updateHomeProfile();
            this.profileUpdate = {
                home: true,
                alert: true,
            };
        },
        changeDrawTab(key) {
            this.selectedAlertPanel = key;
        },
        darkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
        updateHomeProfile() {
            putUsers({
                meta_value: "home",
                profile_id:
                    localStorage.getItem("homeId") == "null"
                        ? 0
                        : localStorage.getItem("homeId"),
            }).then(() => {
                this.profileUpdate = {
                    home: true,
                    alert: this.profileUpdate.alert,
                };
            });
        },
        updateAlertProfile() {
            putUsers({
                meta_value: "alert",
                profile_id:
                    localStorage.getItem("alertId") == "null"
                        ? 0
                        : localStorage.getItem("alertId"),
            }).then(() => {
                this.profileUpdate = {
                    home: this.profileUpdate.home,
                    alert: true,
                };
            });
        },
        updateWidth() {
            if (window.innerWidth - 32 - 25 - 16 - 140 - 80 - 32 - 48 > 1250) {
                this.showMenuButton = false;
            } else {
                this.showMenuButton = true;

                this.checkMovability();
            }
        },
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateWidth();
            });
        },
        moveDiv(direction) {
            let counter = 0;
            const interval = setInterval(() => {
                this.checkMovability();
                if (counter < 100) {
                    document.getElementById("timeDiv").scrollLeft +=
                        5 * direction;
                    counter += 2;
                } else clearInterval(interval);
            }, 1);
        },
        checkMovability() {
            if (document.getElementById("timeDiv").scrollLeft === 0) {
                this.disableToMoveLeft = true;
                this.disableToMoveRight = false;
            } else if (
                document.getElementById("timeDiv").scrollLeft +
                    document.getElementById("timeDiv").clientWidth >
                1430
            ) {
                this.disableToMoveRight = true;
                this.disableToMoveLeft = false;
            } else {
                this.disableToMoveLeft = false;
                this.disableToMoveRight = false;
            }
        },
        checkPumpingStatus() {
            getPumpingStatus().then((res) => {
                this.pumpingStatusList = res.data;
                let trueCount = 0;
                res.data.map((item) => {
                    if (item.alive) {
                        trueCount += 1;
                    }
                });
                if (trueCount === res.data.length) {
                    this.pumpingStatus = "success";
                } else if (trueCount === 0) {
                    this.pumpingStatus = "fail";
                } else {
                    this.pumpingStatus = "partial";
                }
            });
        },
    },
    mounted() {
        if (this.pumpingStatusTimer !== null) {
            clearInterval(this.pumpingStatusTimer);
        }
        this.checkPumpingStatus();
        this.pumpingStatusTimer = setInterval(
            () => this.checkPumpingStatus(),
            30 * 1000
        );
        const permission = JSON.parse(
            localStorage.getItem("permission")
        ).frontPermission;
        if (!Object.prototype.hasOwnProperty.call(permission, "alert"))
            this.loadRing = false;
        if (permission.book.function.includes("dailySummaryIcon")) {
            this.loadDailySummaryIcon = 1;
        }
        const accountChild = this.getChild("account");

        if (accountChild.includes("management")) this.loadManagement = 1;
        if (accountChild.includes("permission")) this.loadPermission = 1;
        if (accountChild.includes("config")) this.loadSettings = 1;
        if (accountChild.includes("profile")) this.loadSecurity = 1;

        this.profileUpdate.home = false;
        this.profileUpdate.alert = false;
        this.logoutLoading = false;
        setTimeout(() => {
            this.updateWidth();
            this.onScreenResize();
            document
                .getElementById("timeDiv")
                .addEventListener("scroll", () => {
                    this.checkMovability();
                });
        }, 1200);
        const today = new Date();
        today.setDate(today.getDate() - 1);

        this.selectedDailySummaryDate = today.toISOString().substr(0, 10);
    },
    destroyed() {
        clearInterval(this.pumpingStatusTimer);
    },
    created() {
        this.getServerAction();
        getHolidayData().then((res) => {
            const filtered = res.data.filter((data) => data.enable === 1);
            filtered.forEach((item) => {
                item.date =
                    item.year +
                    "-" +
                    item.month +
                    "-" +
                    item.day +
                    ", " +
                    this.dayOfWeek[
                        dayjs(
                            item.year + "-" + item.month + "-" + item.day
                        ).day()
                    ];
                if (!this.symbolOptions.includes(item.symbol)) {
                    this.symbolOptions.push(item.symbol);
                }
                if (!this.yearOptions.includes(item.year)) {
                    this.yearOptions.push(item.year);
                }
            });
            this.holidayData = filtered;
        });
        this.getSymbolDPAction();
    },
};
</script>
<style scoped>
div >>> .v-application--wrap {
    min-height: calc(100vh - 40px);
}
div >>> .v-dialog {
    overflow: hidden;
}
div >>> .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

div.scrollmenu {
    overflow: overlay;
    white-space: nowrap;
}
div.scrollmenu::-webkit-scrollbar {
    display: none;
}

div.scrollmenu div {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}
.pumpingStatusTooltip.v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
}
</style>
