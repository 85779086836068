import { getDailyWinner } from "@services/alert/daily-winner";
import common from "@assets/js/common.js";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 11.36,
            "profitClosedMT4": 3,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938618,
            "user": "Demo:MT4-Demo:xxxxx69",
            "server_name": "MT4-Demo",
            "login": "xxxxx69",
            "closed_profit_eod": 11.36,
            "closed_profit": 3
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 35.4,
            "profitClosedMT4": 58.97,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938618,
            "user": "Demo:MT4-Demo:xxxxx34",
            "server_name": "MT4-Demo",
            "login": "xxxxx34",
            "closed_profit_eod": 35.4,
            "closed_profit": 58.97
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 16.9,
            "profitClosedMT4": 20,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938659,
            "user": "Demo:MT4-Demo:xxxxx13",
            "server_name": "MT4-Demo",
            "login": "xxxxx13",
            "closed_profit_eod": 16.9,
            "closed_profit": 20
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 1330,
            "profitClosedMT4": -218,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938645,
            "user": "Demo:MT4-Demo:xxxxx09",
            "server_name": "MT4-Demo",
            "login": "xxxxx09",
            "closed_profit_eod": 1330,
            "closed_profit": -218
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 18.23,
            "profitClosedMT4": 18.23,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938618,
            "user": "Demo:MT5-Demo:xxxxx07",
            "server_name": "MT5-Demo",
            "login": "xxxxx07",
            "closed_profit_eod": 18.23,
            "closed_profit": 18.23
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 46,
            "profitClosedMT4": 67,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938649,
            "user": "Demo:MT5-Demo:xxxxx74",
            "server_name": "MT5-Demo",
            "login": "xxxxx74",
            "closed_profit_eod": 46,
            "closed_profit": 67
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 68.6,
            "profitClosedMT4": 54.9,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938647,
            "user": "Demo:MT4-Demo:xxxxx68",
            "server_name": "MT4-Demo",
            "login": "xxxxx68",
            "closed_profit_eod": 68.6,
            "closed_profit": 54.9
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 85,
            "profitClosedMT4": 85,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938636,
            "user": "Demo:MT5-Demo:xxxxx24",
            "server_name": "MT5-Demo",
            "login": "xxxxx24",
            "closed_profit_eod": 85,
            "closed_profit": 85
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 11.7,
            "profitClosedMT4": 49.7,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938659,
            "user": "Demo:MT4-Demo:xxxxx21",
            "server_name": "MT4-Demo",
            "login": "xxxxx21",
            "closed_profit_eod": 11.7,
            "closed_profit": 49.7
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 13.2,
            "profitClosedMT4": 2.25,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938630,
            "user": "Demo:MT5-Demo:xxxxx84",
            "server_name": "MT5-Demo",
            "login": "xxxxx84",
            "closed_profit_eod": 13.2,
            "closed_profit": 2.25
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 12.6,
            "profitClosedMT4": 19.4,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938630,
            "user": "Demo:MT5-Demo:xxxxx19",
            "server_name": "MT5-Demo",
            "login": "xxxxx19",
            "closed_profit_eod": 12.6,
            "closed_profit": 19.4
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 28.5,
            "profitClosedMT4": 22.7,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938659,
            "user": "Demo:MT5-Demo:xxxxx37",
            "server_name": "MT5-Demo",
            "login": "xxxxx37",
            "closed_profit_eod": 28.5,
            "closed_profit": 22.7
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "messageType": "MSG_TYPE_MONITOR_DAILY_WINNER_LOSER",
            "priority": 1,
            "profitClosed": 93.85,
            "profitClosedMT4": 39.58,
            "recordDate": "2023-08-25",
            "threshold": 10,
            "timestamp": 1692938659,
            "user": "Demo:MT5-Demo:xxxxx35",
            "server_name": "MT5-Demo",
            "login": "xxxxx35",
            "closed_profit_eod": 93.85,
            "closed_profit": 39.58
        },
    ],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Closed Profit EOD",
            value: "closed_profit_eod",
            align: "right",
        },
        // {
        //     text: "Closed Profit MT",
        //     value: "closed_profit",
        //     align: "right",
        // },
    ],
    csvHeader: {
        "Server Name": "server_name",
        Login: "login",
        Books: "books",
        "Closed Profit EOD": "closed_profit_eod",
        "Closed Profit MT": "closed_profit",
    },
    selectedDate: null,
};

const actions = {
    getDailyWinnerAction({ commit, state }, data) {
        if (common.getMT4TimeString().split(" ")[0] !== state.selectedDate) {
            commit("UPDATE_LOADING", true);
            getDailyWinner(data).then((res) => {
                res.data.forEach((item) => {
                    item["books"] = item.books.replace(/[[\]]/g, "");
                });
                commit("UPDATE_ALERT_DATA", res.data);
                commit("UPDATE_LOADING", false);
            });
        }
    },
    processDailyWinner({ commit, state }, data) {
        if (common.getMT4TimeString().split(" ")[0] === state.selectedDate) {
            commit("UPDATE_LOADING", true);
            let result = [];
            for (let item in data) {
                if (item.includes("--winner")) {
                    let temp = data[item];
                    temp.books = temp.books.join(",");
                    temp.server_name = temp.user.split(":")[1];
                    temp.login = temp.user.split(":")[2];
                    temp.closed_profit_eod = temp.profitClosed;
                    temp.closed_profit = temp.profitClosedMT4;
                    result.push(temp);
                }
            }
            commit("UPDATE_ALERT_DATA", result);
            commit("UPDATE_LOADING", false);
        }
    },
};

const mutations = {
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
