export function getLoginTrades(query) {
    if (query.liveOrHis === true) {
        const mockData = {
            status: 200,
            data: [
                {
                    Broker: "Demo",
                    ClosePrice: 1915.56,
                    CloseTime: 0,
                    Cmd: 0,
                    Comment: "",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 0],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxxx41",
                    Magic: 0,
                    OpenPrice: 1932.62,
                    OpenTime: 1691427839,
                    Order: "yyyyyy21",
                    Platform: "MT4",
                    PositionID: "zzzzzz21",
                    Profit: -170.6,
                    ProfitMode: 0,
                    Reason: 5,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933697,
                    Tp: 1940,
                    TypeName: "Forex",
                    Volume: 10,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.95,
                        CurrentBid: 1915.72,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: 19157.2,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 19326.199999999997,
                        ProfitBeforeToday: -160.0999999999999,
                        ProfitEOD: -8.899999999998727,
                        ProfitTotal: -168.99999999999864,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 460,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.1,
                },
                {
                    Broker: "Demo",
                    ClosePrice: 1915.56,
                    CloseTime: 0,
                    Cmd: 0,
                    Comment: "",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 0],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxxx41",
                    Magic: 0,
                    OpenPrice: 1918.41,
                    OpenTime: 1691604607,
                    Order: "yyyyyy71",
                    Platform: "MT4",
                    PositionID: "zzzzzz71",
                    Profit: -57,
                    ProfitMode: 0,
                    Reason: 5,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933697,
                    Tp: 1930,
                    TypeName: "Forex",
                    Volume: 20,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.95,
                        CurrentBid: 1915.72,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: 38314.4,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 38368.200000000004,
                        ProfitBeforeToday: -36.00000000000364,
                        ProfitEOD: -17.799999999997453,
                        ProfitTotal: -53.80000000000109,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 460,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.2,
                },
                {
                    Broker: "Demo",
                    ClosePrice: 1915.56,
                    CloseTime: 0,
                    Cmd: 0,
                    Comment: "",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 0],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxxx41",
                    Magic: 0,
                    OpenPrice: 1934.01,
                    OpenTime: 1691415290,
                    Order: "yyyyyy75",
                    Platform: "MT4",
                    PositionID: "zzzzzz75",
                    Profit: -184.5,
                    ProfitMode: 0,
                    Reason: 5,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933697,
                    Tp: 1945,
                    TypeName: "Forex",
                    Volume: 10,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.95,
                        CurrentBid: 1915.72,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: 19157.2,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 19340.1,
                        ProfitBeforeToday: -174.0000000000009,
                        ProfitEOD: -8.899999999998727,
                        ProfitTotal: -182.89999999999964,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 460,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.1,
                },
                {
                    Broker: "Demo",
                    ClosePrice: 1915.56,
                    CloseTime: 0,
                    Cmd: 0,
                    Comment: "",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 0],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxxx41",
                    Magic: 0,
                    OpenPrice: 1915.17,
                    OpenTime: 1691687995,
                    Order: "yyyyyy15",
                    Platform: "MT4",
                    PositionID: "zzzzzz15",
                    Profit: 7.8,
                    ProfitMode: 0,
                    Reason: 5,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933697,
                    Tp: 1930,
                    TypeName: "Forex",
                    Volume: 20,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.95,
                        CurrentBid: 1915.72,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: 38314.4,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 38303.4,
                        ProfitBeforeToday: 28.799999999996544,
                        ProfitEOD: -17.799999999997453,
                        ProfitTotal: 10.99999999999909,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 460,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.2,
                },
                {
                    Broker: "Demo",
                    ClosePrice: 1915.56,
                    CloseTime: 0,
                    Cmd: 0,
                    Comment: "",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 0],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxxx41",
                    Magic: 0,
                    OpenPrice: 1922.03,
                    OpenTime: 1691600220,
                    Order: "yyyyyy31",
                    Platform: "MT4",
                    PositionID: "zzzzzz31",
                    Profit: -64.7,
                    ProfitMode: 0,
                    Reason: 5,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933697,
                    Tp: 1933,
                    TypeName: "Forex",
                    Volume: 10,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.95,
                        CurrentBid: 1915.72,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: 19157.2,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 19220.3,
                        ProfitBeforeToday: -54.20000000000073,
                        ProfitEOD: -8.899999999998727,
                        ProfitTotal: -63.099999999999454,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 460,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.1,
                },
                {
                    Broker: "Demo",
                    ClosePrice: 1915.56,
                    CloseTime: 0,
                    Cmd: 0,
                    Comment: "",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 0],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxxx41",
                    Magic: 0,
                    OpenPrice: 1913.05,
                    OpenTime: 1692891364,
                    Order: "yyyyyy00",
                    Platform: "MT4",
                    PositionID: "zzzzzz00",
                    Profit: 150.6,
                    ProfitMode: 0,
                    Reason: 5,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933697,
                    Tp: 0,
                    TypeName: "Forex",
                    Volume: 60,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.95,
                        CurrentBid: 1915.72,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: 114943.2,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 114783,
                        ProfitBeforeToday: 213.59999999999673,
                        ProfitEOD: -53.39999999999236,
                        ProfitTotal: 160.20000000000437,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 460,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.6,
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    } else {
        const mockData = {
            status: 200,
            data: [
                {
                    Broker: "Demo",
                    ClosePrice: 1915.93,
                    CloseTime: 1692933672,
                    Cmd: 1,
                    Comment: "V3 - SELL",
                    Commission: 0,
                    ContractSize: 100000,
                    ConvRates: [1, 1],
                    CurrentVolume: 0,
                    Digits: 2,
                    Login: "xxxxx91",
                    Magic: 2,
                    OpenPrice: 1916.89,
                    OpenTime: 1692925260,
                    Order: "yyyyyy37",
                    Platform: "MT4",
                    PositionID: "zzzzzz37",
                    Profit: 9.6,
                    ProfitMode: 0,
                    Reason: 1,
                    Server: "MT4-Demo",
                    Sl: 0,
                    Storage: 0,
                    Symbol: "XAUUSD-P",
                    Timestamp: 1692933672,
                    Tp: 0,
                    TypeName: "Forex",
                    Volume: 10,
                    force: false,
                    priority: 1,
                    tradeExtraInfoOZ: {
                        percentageInA: 100,
                        percentageInAName: ["B"],
                        percentageInB: 0,
                        percentageInBName: ["S"],
                    },
                    tradePriceInfo: {
                        CurrentAsk: 1915.85,
                        CurrentBid: 1915.62,
                        EodAsk: 1917.21,
                        EodBid: 1916.61,
                        EodPriceTime: 1692835200,
                        EodServerId: 1,
                        ExchangeAsk: 1,
                        ExchangeBid: 1,
                        ExchangeSymbol: "USDUSD",
                    },
                    tradeProfitInfo: {
                        Exposure: -19158.5,
                        ExposureBuy: "XAU",
                        ExposureSell: "USD",
                        ExposureVolume: 19168.9,
                        ProfitBeforeToday: 0,
                        ProfitEOD: 9.600000000000364,
                        ProfitTotal: 9.600000000000364,
                        ProfitTotalPartialClosed: 0,
                    },
                    version: {
                        version: 374,
                    },
                    volumeWeight: 1,
                    weightedVolume: 0.1,
                },
            ],
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
}
