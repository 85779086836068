import common from "@assets/js/common";
import { getDataFastTrade } from "@services/alert/fast-trade";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Volume", value: "volume", align: "right" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Book", value: "books", align: "left" },
        { text: "Duration", value: "duration", align: "right" },
    ],
    csvHeader: {
        Login: "login",
        Volume: "volume",
        Symbol: "symbol",
        Book: "books",
        Duration: "duration",
        "Server Name": "server_name",
        "Threshold Time": "threshold_time",
        "Threshold Volume": "threshold_volume",
        Order: "order",
        "Open Time": "open_time",
        "Close Time": "close_time",
        "Trigger Time": "trigger_time",
    },
    selectedDate: null,
};

const actions = {
    /**
     * Fetch large volume data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getDataFastTradeAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getDataFastTrade(data).then((res) => {
            res.data.forEach((item) => {
                item["books"] = item["books"]?.replace(/[[\]]/g, "") || "N/A";
            });
            commit("UPDATE_ALERT_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    processFastTrade({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (state.selectedDate >= minimumAcceptableTime) {
            const exist = state.alertData.find(
                (item) => item.login == data.login && item.order == data.order
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = dayjs(data).unix();
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
