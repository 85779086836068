import "@plugins/axios";
import store from "@store/store";

export const compareVersion = (newVersion, oldVersion) => {
  const oldArr = oldVersion.split('.')
  const newArr = newVersion.split('.')
  const oldLen = oldArr.length
  const newLen = newArr.length
  const minLen = Math.min(oldLen, newLen)
  let i = 0
  for (; i < minLen; i++) {
    const oldVal = parseInt(oldArr[i])
    const newVal = parseInt(newArr[i])
    if (newVal > oldVal) {
      return 1
    } else if (newVal < oldVal) {
      return -1
    }
  }
  if (newLen > oldLen) {
    for (let j = 0; j < newLen; j++) {
      if (parseInt(newArr[j]) !== 0) {
        return 1
      }
    }
  } else if (newLen < oldLen) {
    for (let j = 0; j < oldLen; j++) {
      if (parseInt(oldArr[j]) !== 0) {
        return -1
      }
    }
  }
  return 0
}

export const checkVersion = () => {
  const mockData = {
    status: 200,
    data: {
        "version": "1.2.3"
    }
};
const returnVal = new Promise((resolve, _) => resolve(mockData));
return returnVal;
}