import common from "@assets/js/common";

const state = {
    loading: false,
    monitorData: [],
    mockedData:[
        {
          books: ["B"],
          broker: "Demo",
          exposure: 40229.28,
          exposureThreshold: 10,
          exposureVolume: 0.32,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "GBP",
          timestamp: 1692940546,
          user: "Demo:MT5-Demo:xxxxxx86",
          trigger_time: "2023-08-25 15:15:46",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: -2488.05,
          exposureThreshold: 10,
          exposureVolume: 0.01,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940584,
          user: "Demo:MT5-Demo:xxxxxx22",
          trigger_time: "2023-08-25 15:16:24",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: -1257.45,
          exposureThreshold: 10,
          exposureVolume: 0.01,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940627,
          user: "Demo:MT5-Demo:xxxxxx75",
          trigger_time: "2023-08-25 15:17:07",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: -8800.89,
          exposureThreshold: 10,
          exposureVolume: 0.11,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "CHF",
          timestamp: 1692940517,
          user: "Demo:MT5-Demo:xxxxxx48",
          trigger_time: "2023-08-25 15:15:17",
        },
        {
          books: ["S"],
          broker: "Demo",
          exposure: -2281817.3,
          exposureThreshold: 10,
          exposureVolume: 12.2,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940544,
          user: "Demo:MT5-Demo:xxxxxx90",
          trigger_time: "2023-08-25 15:15:44",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: 19167.4,
          exposureThreshold: 10,
          exposureVolume: 0.1,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "XAU",
          timestamp: 1692940490,
          user: "Demo:MT5-Demo:xxxxxx38",
          trigger_time: "2023-08-25 15:14:50",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: 23740.44,
          exposureThreshold: 10,
          exposureVolume: -0.2,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940421,
          user: "Demo:MT5-Demo:xxxxxx06",
          trigger_time: "2023-08-25 15:13:41",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: -1916.8,
          exposureThreshold: 10,
          exposureVolume: 0.01,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940519,
          user: "Demo:MT5-Demo:xxxxxx16",
          trigger_time: "2023-08-25 15:15:19",
        },
        {
          books: ["S"],
          broker: "Demo",
          exposure: 47919.7,
          exposureThreshold: 10,
          exposureVolume: -0.25,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940759,
          user: "Demo:MT5-Demo:xxxxxx75",
          trigger_time: "2023-08-25 15:19:19",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: 197463.53,
          exposureThreshold: 10,
          exposureVolume: -1.0300000000000002,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "USD",
          timestamp: 1692940430,
          user: "Demo:MT5-Demo:xxxxxx03",
          trigger_time: "2023-08-25 15:13:50",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: -5387056.7,
          exposureThreshold: 10,
          exposureVolume: -28.10000000000001,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "XAU",
          timestamp: 1692940594,
          user: "Demo:MT5-Demo:xxxxxx73",
          trigger_time: "2023-08-25 15:16:34",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: 84240.88,
          exposureThreshold: 10,
          exposureVolume: 0.7600000000000002,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "GBP",
          timestamp: 1692940571,
          user: "Demo:MT5-Demo:xxxxxx20",
          trigger_time: "2023-08-25 15:16:11",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: 11.5,
          exposureThreshold: 10,
          exposureVolume: 0.0999999999999997,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "GBP",
          timestamp: 1692940645,
          user: "Demo:MT5-Demo:xxxxxx70",
          trigger_time: "2023-08-25 15:17:25",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposure: 134143.8,
          exposureThreshold: 10,
          exposureVolume: 0.7000000000000001,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE",
          priority: 1,
          symbol: "XAU",
          timestamp: 1692940777,
          user: "Demo:MT5-Demo:xxxxxx34",
          trigger_time: "2023-08-25 15:19:37",
        },
      ]
      ,
    monitorHeader: [
        { text: "User", value: "user", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Broker", value: "broker", align: " d-none" },
        { text: "Exposure", value: "exposure", align: "left" },
        { text: "TH", value: "exposureThreshold", align: "left" },
        { text: "Message Type", value: "messageType", align: " d-none" },
        { text: "Timestamp", value: "timestamp", align: " d-none" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Exposure", value: "exposure", align: "left" },
        { text: "Threshold", value: "exposure_threshold", align: "left" },
        { text: "Status", value: "alert_status", align: "left" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    currentBook: "",
    lastSelectedBook: "",
};

const actions = {
    /**
     * Process alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     *
     * @return  {[type]}          [return description]
     */
    processLargeExposure({ commit, state }, data) {
        if (
            state.currentBook != state.lastSelectedBook ||
            parseInt(Date.now() / 1000) % 5 == 0
        ) {
            let result = [];
            for (let item in data) {
                if (data[item].books[0] == state.currentBook) {
                    let temp = data[item];
                    // temp["symbol"] = temp["symbol"].split("_")[0];
                    temp["trigger_time"] =
                        common.convertUnixtimeToDatetimeString(temp.timestamp);
                    result.push(temp);
                }
            }
            state.lastSelectedBook = state.currentBook;
            // commit("UPDATE_MONITOR_DATA", result);
        }
    },
    getMandateReadyAction({ commit, state }) {
        setTimeout(() => {
            commit("UPDATE_MONITOR_DATA", state.mockedData);
        }, 200);
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_CURRENT_BOOK(state, data) {
        state.currentBook = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
