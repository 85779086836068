import { getLoginHistory } from "@services/statistic/login-history";
import { getLoginSummary } from "@services/statistic/login-summary";
import { getLoginGroupChange } from "@services/statistic/login-group-change";
import { getLoginTrades } from "@services/statistic/login-trades";

const state = {
    loginHistoryChartTimeDate: [],
    loginHistoryChartSeriesData: [],
    loginHistoryChartLegendData: [],
    loginGroupChangeData: [],
    loginCloseTradesData: [],
    loginOpenTradesData: [],
    loginGroupChangeHeader: [
        { text: "Server", value: "server_id", width: 80, align: "left" },
        { text: "From", value: "from_group", width: 80, align: "left" },
        {
            text: "To",
            value: "to_group",
            width: 80,
            align: "left",
        },
        {
            text: "Trigger Time",
            value: "created_at",
            width: 80,
            align: "left",
        },
    ],
    loginSummary: {},
    loading: false,
    openTradeLoading: false,
    closeTradesHeader: [
        { text: "Position ID", width: 100, align: "left" },
        { text: "Order", width: 80, align: "left" },
        { text: "Type", width: 60, align: "left" },
        { text: "Lots", width: 80, align: "right" },
        { text: "Open Time", width: 170, align: "left" },
        { text: "Close Time", width: 170, align: "left" },
        { text: "Open Price", width: 100, align: "right" },
        {
            text: "Close Price",
            width: 100,
            align: "right",
        },
        { text: "EOD Ask", width: 80, align: "right" },
        { text: "EOD Bid", width: 80, align: "right" },
        { text: "Profit", value:"profit",width: 100, align: "right" },
        {
            text: "Profit MT",
            value: "ProfitTotal",
            width: 120,
            align: "right",
        },
        { text: "Profit EOD",value: "", width: 100, align: "right" },
        { text: "TP", width: 80, align: "right" },
        { text: "SL", width: 80, align: "right" },
        { text: "Reason", width: 80, align: "left" },
        { text: "Commission", width: 90, align: "right" },
        { text: "Swaps", width: 80, align: "right" },
        {
            text: "Exchange&Calculation",
            width: 90,
            align: "left",
        },
    ],
    openTradesHeader: [
        { text: "Position ID", width: 100, align: "left" },
        { text: "Order", width: 80, align: "left" },
        { text: "Type", width: 60, align: "left" },
        { text: "Lots", width: 80, align: "right" },
        { text: "Open Time", width: 170, align: "left" },
        { text: "Open Price", width: 100, align: "right" },
        {
            text: "Current Ask",
            width: 100,
            align: "right",
        },
        {
            text: "Current Bid",
            width: 100,
            align: "right",
        },
        { text: "EOD Ask", width: 80, align: "right" },
        { text: "EOD Bid", width: 80, align: "right" },
        { text: "Profit",value:"profit", width: 100, align: "right" },
        {
            text: "Profit MT",value: "ProfitTotal",
            width: 120,
            align: "right",
        },
        { text: "Profit EOD", width: 100, align: "right" },
        { text: "TP", width: 80, align: "right" },
        { text: "SL", width: 80, align: "right" },
        { text: "Reason", width: 80, align: "left" },
        { text: "Commission", width: 90, align: "right" },
        { text: "Swaps", width: 80, align: "right" },
        {
            text: "Exchange&Calculation",
            width: 90,
            align: "left",
        },
    ],
    reasonList: {
        0: "Client",
        1: "Expert",
        2: "Dealer",
        3: "Signal",
        4: "Gateway",
        5: "Mobile",
        6: "Web",
        7: "API",
    },
    profitMode: {
        MT4: {
            0: "Forex",
            1: "CFD",
            2: "FUTURES",
        },
        MT5: {
            0: "Forex",
            1: "FUTURES",
            2: "CFD",
            3: "CFD INDEX",
            4: "CFD LEVERAGE",
            5: "FOREX NO LEVERAGE",
        },
    },
};

const actions = {
    /**
     * Get login history detail
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params contain login and broker id]
     * @return  {[type]}          [return description]
     */
    getLoginHistoryAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        getLoginHistory(params).then((res) => {
            let chartTimeDate = res.data.xdata;
            let loginHistoryChartTimeDate = chartTimeDate.map(function (str) {
                return str.substring(2, str.length - 9);
            });

            commit(
                "UPDATE_LOGIN_HISTORY_CHART_TIME_DATE",
                loginHistoryChartTimeDate
            );

            const dailyPnl = {
                name: "Daily PNL",
                type: "line",
                symbolSize: 8,
                emphasis: { scale: false },
                symbol: "circle",
                yAxisIndex: 2,
                data: res.data.ydata.daily_pnl,
            };

            const balance = {
                name: "Balance",
                type: "line",
                emphasis: { scale: false },
                symbol: "circle",
                data: res.data.ydata.balance,
            };

            const equity = {
                name: "Equity",
                type: "line",
                emphasis: { scale: false },
                symbol: "circle",
                data: res.data.ydata.equity,
            };

            const dpm = {
                name: "DPM",
                type: "bar",
                emphasis: { scale: false },
                symbol: "circle",
                yAxisIndex: 1,
                data: res.data.ydata.dpm,
            };

            let legendData = [];
            let seriesData = [];
            legendData.push(dailyPnl.name);
            seriesData.push(dailyPnl);
            legendData.push(balance.name);
            seriesData.push(balance);
            legendData.push(equity.name);
            seriesData.push(equity);
            legendData.push(dpm.name);
            seriesData.push(dpm);

            commit("UPDATE_LOGIN_HISTORY_CHART_SERIES_DATA", seriesData);
            commit("UPDATE_LOGIN_HISTORY_CHART_LEGEND_DATA", legendData);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * Get login summary detail
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    getLoginSummaryAction({ commit }, params) {
        getLoginSummary(params).then((res) => {
            if (res.data.length !== 0) {
                commit("UPDATE_LOGIN_SUMMARY", res.data[0]);
            } else {
                commit("UPDATE_LOGIN_SUMMARY", {});
            }
        });
    },
    getLoginGroupChangeAction({ commit }, params) {
        getLoginGroupChange(params).then((res) => {
            if (res.data.length !== 0) {
                commit("UPDATE_LOGIN_GROUP_CHANGE_INFO", res.data);
            } else {
                commit("UPDATE_LOGIN_GROUP_CHANGE_INFO", []);
            }
        });
    },
    getLoginCloseTradesAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        getLoginTrades(params)
            .then((res) => {
                if (res.length !== 0) {
                    state.loginCloseTradesData = res.data;
                } else {
                    state.loginCloseTradesData = [];
                }
                commit("UPDATE_LOADING", false);
            })
            .catch((error) => {
                console.log(error);
                commit("UPDATE_LOADING", false);
            });
    },
    getLoginOpenTradesAction({ commit }, params) {
        state.openTradeLoading = true;
        getLoginTrades(params)
            .then((res) => {
                if (res.length !== 0) {
                    state.loginOpenTradesData = res.data;
                } else {
                    state.loginOpenTradesData = [];
                }
                state.openTradeLoading = false;
            })
            .catch((error) => {
                console.log(error);
                commit("UPDATE_LOADING", false);
            });
    },
};

const mutations = {
    UPDATE_LOGIN_SUMMARY(state, data) {
        state.loginSummary = data;
    },
    UPDATE_LOGIN_GROUP_CHANGE_INFO(state, data) {
        state.loginGroupChangeData = data;
    },
    /**
     * Update login history chart time
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOGIN_HISTORY_CHART_TIME_DATE(state, data) {
        state.loginHistoryChartTimeDate = data;
    },
    /**
     * Update login history chart series data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOGIN_HISTORY_CHART_SERIES_DATA(state, data) {
        state.loginHistoryChartSeriesData = data;
    },
    /**
     * Update login history chart time legend data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOGIN_HISTORY_CHART_LEGEND_DATA(state, data) {
        state.loginHistoryChartLegendData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_CLOSE_TRADE_DATA(state, data) {
        state.loginCloseTradesData = data;
    },
    UPDATE_OPEN_TRADE_DATA(state, data) {
        state.loginOpenTradesData = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
