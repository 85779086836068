<template>
    <v-app class="app">
        <v-main>
            <router-view />

            <v-snackbar
                v-model="$store.state.snackbar"
                :color="$store.state.snackcolor"
                :timeout="$store.state.snacktimeout"
                top
            >
                {{ $store.state.snackmsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        :color="$store.state.snackclosecolor"
                        text
                        v-bind="attrs"
                        :id="$store.state.snackbarbtnid"
                        @click="dismiss()"
                    >
                        {{ $store.state.snackbarbtn }}
                    </v-btn>
                </template>
            </v-snackbar>
            <Transition name="slide">
                <div
                    v-if="showAlert"
                    class="update-alert"
                    style="padding: 10px"
                    @click="handleCloseAlert"
                >
                    <v-icon
                        class="icon-close"
                        size="18"
                        color="#2196f3"
                        style="position: absolute"
                        >mdi-close</v-icon
                    >
                    <p>
                        A new and improved version of the application is now
                        available.
                    </p>
                    <v-btn
                        class="mr-2"
                        color="primary"
                        elevation="3"
                        @click="handleRefresh"
                    >
                        <!-- <v-icon size="18">mdi-refresh</v-icon> -->
                        refresh
                    </v-btn>
                    <v-btn
                        elevation="3"
                        :loading="logBtnLoading"
                        @click="getLog"
                    >
                        <!-- <v-icon size="18">mdi-text-box-outline</v-icon> -->
                        view update
                    </v-btn>
                </div>
            </Transition>
            <v-dialog
                v-model="updateDialogShow"
                :fullscreen="fullscreen"
                width="800"
                :hide-overlay="true"
                :persistent="true"
            >
                <v-card class="pt-0">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            UPDATE LOG
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="white"
                            icon
                            @click="updateDialogShow = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <div id="update-log"></div>
                    </v-card-actions>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="handleRefresh">
                            REFRESH NOW
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
import * as DOMPurify from "dompurify";
import { marked } from "marked";
import { mapState } from "vuex";
import { checkVersion } from "@utils/util";

export default {
    name: "App",
    components: {},
    data: () => ({
        snackmsg: "Error",
        snackstatus: true,
        snackclosecolor: "white",
        alertChannel: [],
        showAlert: false,
        logBtnLoading: false,
        fullscreen: false,
        updateDialogShow: false,
        checkVersionTimer: null,
    }),
    computed: {
        ...mapState(["versionFlag", "latestVersion"]),
    },
    watch: {
        versionFlag(val) {
            if (val) {
                setTimeout(() => {
                    this.showAlert = true;
                }, 500);
            }
        },
    },
    methods: {
        dismiss() {
            this.$store.commit("dismissSnackbar");
        },
        async handleRefresh() {
            clearInterval(this.checkVersionTimer);
            this.checkVersionTimer = null;
            // 刷新前重设版本号
            const res = await axios.get("version.json", {
                headers: { "Cache-Control": "no-cache" },
            });
            const version = res.data.version;
            console.log("Refresh at App.vue: ", version);
            localStorage.setItem("version", version);
            location.reload();
        },
        handleCloseAlert() {
            this.$store.dispatch("setVersionFlag", -1);
            this.showAlert = false;
        },
        getLog(e) {
            e.stopPropagation();
            axios
                .get("update-log.md", {
                    headers: { "Cache-Control": "no-cache" },
                })
                .then((res) => {
                    console.log(res);
                    this.logBtnLoading = false;
                    this.updateDialogShow = true;
                    this.$nextTick(() => {
                        document.getElementById("update-log").innerHTML =
                            DOMPurify.sanitize(marked.parse(res.data));
                    });
                })
                .catch((e) => {
                    console.error(e);
                    this.logBtnLoading = false;
                });
        },
    },
    mounted() {
        // this.loadAlertChannel();
        this.checkVersionTimer = setInterval(checkVersion, 5 * 60 * 1000);
    },
};
</script>
<style>
/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
    height: 20px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9;
}
.app {
    overflow: overlay;
}
.app::-webkit-scrollbar {
    display: none;
}
.update-alert {
    position: fixed;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    border: 1px solid #000;
    background-color: #403d3d;
    padding: 24px 36px 24px 24px;
    color: #2196f3;
    width: 320px;
    border-radius: 6px;
}
.update-alert .mdi-close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
}
.update-alert .mdi-refresh {
    margin-right: 8px;
}
.slide-enter-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-leave-active {
    transition: all 0.5s ease-out;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(150px);
    opacity: 0;
}
.v-application {
    height: 100%;
}
</style>
