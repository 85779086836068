import common from "@assets/js/common";
import { getTradeOnCredit } from "@services/alert/trade-on-credit";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Equity", value: "equity", align: "right" },
        { text: "Balance", value: "balance", align: "right" },
        { text: "Credit", value: "credit", align: "right" },
        {
            text: "Trigger Time",
            value: "trigger_time",
            width: 170,
            align: "left",
        },
    ],
    csvHeader: {
        Login: "login",
        Books: "books",
        "Equity":"equity",
        "Balance":"balance",
        "Credit":"credit",
        "Equity Credit Ratio Threshold": "equity_credit_ratio_threshold",
        "Trigger Time": "trigger_time",
    },
    selectedDate: null,
};

const actions = {
    /**
     * Fetch large volume data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getDataTradeOnCreditAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getTradeOnCredit(data).then((res) => {
            res.data.forEach((item) => {
                const bracketRemoved = item["books"].replace(/[[\]]/g, "");
                const bookArray = bracketRemoved.split(",");
                if (bookArray.length == 2 && bookArray.includes("STOCKS")) {
                    item["books"] = bookArray
                        .filter((item) => item !== "STOCKS")
                        .join(",");
                } else if (bookArray.length >= 3) {
                    item["books"] = bookArray
                        .filter(
                            (item) =>
                                item !== "STOCKS" &&
                                item !== "EquityAU" &&
                                item !== "EquityUS"
                        )
                        .join(",");
                } else {
                    item["books"] = bookArray.join(",");
                }
            });
            commit("UPDATE_ALERT_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    processTradeOnCredit({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (state.selectedDate >= minimumAcceptableTime) {
            data.books = data.books.replace(/[[\]]/g, "");
            const exist = state.alertData.find(
                (item) =>
                    item.login == data.login &&
                    item.books == data.books &&
                    item.trigger_time == data.trigger_time &&
                    item.balance == data.balance
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = dayjs(data).unix();
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
