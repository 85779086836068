
export function getConfig(type) {
    if(type === "fast-trade"){
        const mockData = {
            status: 200,
            data: [{
                "id": 1,
                "server_id": 1,
                "broker_id": 1,
                "duration_threshold": 120,
                "volume_threshold": 1,
                "priority": 99999,
                "created_at": "2022-10-25 03:10:59",
                "updated_at": "2023-03-01 13:30:15"
            }]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "large-exposure"){
        const mockData = {
            status: 200,
            data: [
                {
                    "id": 1,
                    "server_id": 1,
                    "broker_id": 1,
                    "exposure_threshold": 10,
                    "priority": 99999,
                    "symbol_regx": "*",
                    "group_regx": "*",
                    "created_at": "2022-10-25 03:10:59",
                    "updated_at": "2023-07-07 16:42:04"
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "large-volume"){
        const mockData = {
            status: 200,
            data: [
                {
                    "id": 3,
                    "server_id": 1,
                    "broker_id": 1,
                    "symbol_regx": "*",
                    "volume_threshold": 5,
                    "priority": 2,
                    "created_at": "2023-02-23 14:40:31",
                    "symbol_group": "*",
                    "updated_at": "2023-04-28 22:13:49"
                }
            ]
        };
        const returnVal = new Promise((resolve, _) => resolve(mockData));
        return returnVal;
    }
    if(type === "weighted-volume"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "broker_id": 1,
                "server_id": 1,
                "weighted_volume_threshold": 3,
                "priority": 999,
                "group_regx": "*",
                "created_at": "2023-07-07 11:35:03",
                "updated_at": "2023-07-14 15:10:42"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "locking-position"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "broker_id": 1,
                "lock_volume_threshold": 1,
                "priority": 99999,
                "symbol_regx": "*",
                "symbol_group": "*",
                "created_at": "2022-10-25 03:11:00",
                "updated_at": "2023-07-07 16:43:01"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "mandate"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "broker_id": 1,
                "volume_threshold": 0,
                "profit_threshold": 50,
                "priority": 99999,
                "symbol_regx": "*",
                "symbol_group": "*",
                "created_at": "2022-10-25 03:11:00",
                "updated_at": "2023-07-07 16:40:03"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "profit-taker"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "broker_id": 1,
                "profit_threshold": 100,
                "priority": 99999,
                "symbol_regx": "*",
                "group_regx": "*",
                "created_at": "2022-10-25 03:11:00",
                "updated_at": "2023-03-01 13:30:38"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "watch-list"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 2,
                "broker_id": 1,
                "priority": 1,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "regex[^(xxxxxx99|xxxxxx51|xxxxxx03|xxxxxx11|xxxxxx15|xxxxxx34|xxxxxx93)$]",
                "enable_notification": false,
                "notification_rule": 1,
                "created_at": "2023-02-14 12:22:49",
                "updated_at": "2023-08-08 16:51:12"
            },
            {
                "id": 3,
                "broker_id": 1,
                "priority": 3,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "regex[^(xxxxxx59,xxxxxx41)$]",
                "enable_notification": true,
                "notification_rule": 1,
                "created_at": "2023-02-14 12:23:49",
                "updated_at": "2023-02-20 04:27:18"
            },
            {
                "id": 4,
                "broker_id": 1,
                "priority": 4,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "regex[^(xxxxxx99)$]",
                "enable_notification": true,
                "notification_rule": 1,
                "created_at": "2023-02-17 13:24:59",
                "updated_at": "2023-02-20 04:30:21"
            },
            {
                "id": 9,
                "broker_id": 1,
                "priority": 9,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "regex[^(xxxxxx90)$]",
                "enable_notification": true,
                "notification_rule": 1,
                "created_at": "2023-02-20 10:29:04",
                "updated_at": "2023-02-20 10:29:04"
            },
            {
                "id": 10,
                "broker_id": 1,
                "priority": 10,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "regex[^(xxxxxx50)$]",
                "enable_notification": true,
                "notification_rule": 1,
                "created_at": "2023-02-21 13:01:13",
                "updated_at": "2023-02-21 13:01:13"
            },
            {
                "id": 13,
                "broker_id": 1,
                "priority": 12,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "regex[^(xxxxxx99])$]",
                "enable_notification": true,
                "notification_rule": 1,
                "created_at": "2023-02-23 09:17:19",
                "updated_at": "2023-02-23 14:45:43"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "same-direction"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "priority": 1,
                "broker_id": 1,
                "volume_threshold": 0,
                "interval_threshold": 3,
                "number_threshold": 2,
                "minimum_volume_threshold": 0,
                "created_at": "2023-02-14 12:16:04",
                "updated_at": "2023-03-01 13:30:55"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "trade-on-credit"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "broker_id": 1,
                "equity_credit_ratio_threshold": 1,
                "priority": 99999,
                "book_regx": "*",
                "group_regx": "*",
                "created_at": "2022-10-25 03:11:01",
                "updated_at": "2023-03-01 13:30:59"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "daily-winner"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 8,
                "server_id": 1,
                "broker_id": 1,
                "close_profit_threshold": -100,
                "books": "B",
                "priority": 1,
                "created_at": "2023-08-08 16:31:34",
                "updated_at": "2023-08-08 16:31:34"
            },
            {
                "id": 7,
                "server_id": 1,
                "broker_id": 1,
                "close_profit_threshold": 10,
                "books": "B",
                "priority": 1,
                "created_at": "2023-08-08 16:31:34",
                "updated_at": "2023-08-08 16:32:49"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "daily-loser"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 8,
                "server_id": 1,
                "broker_id": 1,
                "close_profit_threshold": -100,
                "books": "B",
                "priority": 1,
                "created_at": "2023-08-08 16:31:34",
                "updated_at": "2023-08-08 16:31:34"
            },
            {
                "id": 7,
                "server_id": 1,
                "broker_id": 1,
                "close_profit_threshold": 10,
                "books": "B",
                "priority": 1,
                "created_at": "2023-08-08 16:31:34",
                "updated_at": "2023-08-08 16:32:49"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "large-exposure-volume"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "broker_id": 1,
                "volume_threshold": 0.5,
                "priority": 1,
                "symbol_regx": "regex[^(?!(ETH|BTC|ADA|LTC|BCH|DOT|EOS|LNK|XLM|XRP)).*]",
                "group_regx": "*",
                "created_at": "2022-10-25 03:11:23",
                "updated_at": "2023-07-07 16:43:33"
            }
        ]
    }
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "deposit-withdrawal"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "broker_id": 1,
                "withdraw_deposit_threshold": 1,
                "priority": 1,
                "book_regx": "*",
                "group_regx": "*",
                "server_id": 1,
                "login_list": "*"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
    if(type === "tick"){
        const mockData = {
        status: 200,
        data: [
            {
                "id": 1,
                "server_id": 1,
                "server_name": "MT4-DEMO",
                "symbol": "AUDCHF",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 2,
                "server_id": 1,
                "server_name": "MT4-DEMO",
                "symbol": "AUDJPY",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 3,
                "server_id": 1,
                "server_name": "MT5-DEMO",
                "symbol": "AUDUSD",
                "security": "Forex-Main",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 4,
                "server_id": 1,
                "server_name": "MT5-DEMO",
                "symbol": "AUDCAD",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 5,
                "server_id": 1,
                "server_name": "MT5-DEMO",
                "symbol": "EURCHF",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 6,
                "server_id": 1,
                "server_name": "MT4-DEMO",
                "symbol": "AUDNZD",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 7,
                "server_id": 1,
                "server_name": "MT5-DEMO",
                "symbol": "CADCHF",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
            {
                "id": 8,
                "server_id": 1,
                "server_name": "MT5-DEMO",
                "symbol": "CADJPY",
                "security": "Forex",
                "seconds_threshold": 300,
                "continuous_count": "3",
                "email_threshold": 300,
                "telegram_id": "-xxxxxxxx04",
                "created_at": "2022-11-14 12:27:00",
                "updated_at": "2022-11-14 12:27:00"
            },
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
    }
}

export function updateConfig(type,data){
    const mockData = {
        status: 200,
        data: { 'msg': 'Create configuration successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function createConfig(type,data){
    const mockData = {
        status: 200,
        data: { 'msg': 'Update configuration successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function deleteConfig(type,data){
    const mockData = {
        status: 200,
        data: { 'msg': 'Delete configuration successfully' }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
