
export function getDataDepositWithdrawal(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx63",
                "group_name": "manager",
                "amount": 3.08,
                "order": "yyyyy81",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "B"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx13",
                "group_name": "manager",
                "amount": -3.08,
                "order": "yyyyy80",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "B"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx66",
                "group_name": "manager",
                "amount": -3.02,
                "order": "yyyyy82",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "B"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx63",
                "group_name": "manager",
                "amount": 3.02,
                "order": "yyyyy83",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "S"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx63",
                "group_name": "manager",
                "amount": 3.1,
                "order": "yyyyy85",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "S"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx47",
                "group_name": "manager",
                "amount": -3.1,
                "order": "yyyyy84",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "S"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx80",
                "group_name": "manager",
                "amount": -1.55,
                "order": "yyyyy86",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "S"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx21",
                "group_name": "system",
                "amount": -7.57,
                "order": "yyyyy88",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "B"
            },
            {
                "server_name": "MT5-Demo",
                "server_id": 1,
                "login": "xxxxxx78",
                "group_name": "system",
                "amount": -5.9,
                "order": "yyyyy90",
                "cmd": 6,
                "withdraw_deposit_threshold": 1,
                "trigger_time": "2023-08-24 00:05:23",
                "book_name": "B"
            }
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
