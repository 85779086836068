
export function getTick() {
    const mockData = {
        status: 200,
        data: [{
            "server": "MT4-Demo",
            "symbol": "XAUUSD",
            "security": "Metal",
            "threshold": 300,
            "gap": 872,
            "last_update_time": 1693185900,
            "trigger_time": 1693186793
           },{
            "server": "MT4-Demo",
            "symbol": "EURUSD",
            "security": "Forex",
            "threshold": 300,
            "gap": 1272,
            "last_update_time": 1693185576,
            "trigger_time": 1693186848
           },{
            "server": "MT4-Demo",
            "symbol": "USDJPY",
            "security": "Forex",
            "threshold": 300,
            "gap": 372,
            "last_update_time": 1693186476,
            "trigger_time": 1693186793
           }]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
