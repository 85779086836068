
export function getDataLargeVolume(query) {
    const mockData = {
        status: 200,
        data: [{
            "server_id": 2,
            "login": "xxxxxx51",
            "volume": 5,
            "symbol": "USDJPY",
            "books": "[B]",
            "order": "yyyyy70",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-01 12:04:24",
            "close_time": "2023-08-24 07:45:42",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-01 12:04:24",
            "updated_at": "2023-08-20 23:30:20"
        },
        {
            "server_id": 2,
            "login": "xxxxxx51",
            "volume": 5,
            "symbol": "USDJPY",
            "books": "[B]",
            "order": "yyyyy26",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-01 15:33:14",
            "close_time": "2023-08-24 07:48:29",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-01 15:33:14",
            "updated_at": "2023-08-20 23:29:19"
        },
        {
            "server_id": 1,
            "login": "xxxxxx65",
            "volume": 6,
            "symbol": "XAUUSD",
            "books": "[B]",
            "order": "yyyyy93",
            "direction": "LONG",
            "threshold_volume": 5,
            "open_time": "2023-08-07 16:11:57",
            "close_time": "2023-08-24 05:49:08",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-07 16:11:57",
            "updated_at": "2023-08-20 23:29:23"
        },
        {
            "server_id": 2,
            "login": "xxxxxx08",
            "volume": 10,
            "symbol": "XAUUSD",
            "books": "[B]",
            "order": "yyyyy74",
            "direction": "LONG",
            "threshold_volume": 5,
            "open_time": "2023-08-14 05:12:00",
            "close_time": "2023-08-24 08:40:22",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-14 05:12:00",
            "updated_at": "2023-08-20 23:29:15"
        },
        {
            "server_id": 1,
            "login": "xxxxxx11",
            "volume": 5,
            "symbol": "GBPJPY",
            "books": "[S]",
            "order": "yyyyy56",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-15 00:36:19",
            "close_time": "2023-08-24 01:34:52",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-15 00:36:19",
            "updated_at": "2023-08-14 17:36:19"
        },
        {
            "server_id": 2,
            "login": "xxxxxx09",
            "volume": 10,
            "symbol": "XAUUSD",
            "books": "[B]",
            "order": "yyyyy75",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-15 13:53:48",
            "close_time": "2023-08-24 04:28:11",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-15 13:53:48",
            "updated_at": "2023-08-20 23:29:43"
        },
        {
            "server_id": 1,
            "login": "xxxxxx11",
            "volume": 5,
            "symbol": "GBPJPY",
            "books": "[S]",
            "order": "yyyyy79",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-15 17:17:09",
            "close_time": "2023-08-24 01:34:52",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-15 17:17:09",
            "updated_at": "2023-08-15 10:17:09"
        },
        {
            "server_id": 1,
            "login": "xxxxxx11",
            "volume": 5,
            "symbol": "GBPJPY",
            "books": "[Test]",
            "order": "yyyyy89",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-16 12:34:53",
            "close_time": "2023-08-24 01:34:51",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-16 12:34:53",
            "updated_at": "2023-08-16 05:34:53"
        },
        {
            "server_id": 2,
            "login": "xxxxxx08",
            "volume": 12.6,
            "symbol": "XAUUSD",
            "books": "[B]",
            "order": "yyyyy28",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-16 16:15:22",
            "close_time": "2023-08-24 08:48:32",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-16 16:15:22",
            "updated_at": "2023-08-20 23:29:58"
        },
        {
            "server_id": 1,
            "login": "xxxxxx11",
            "volume": 5,
            "symbol": "GBPJPY",
            "books": "[Test]",
            "order": "yyyyy46",
            "direction": "SHORT",
            "threshold_volume": 5,
            "open_time": "2023-08-16 22:50:22",
            "close_time": "2023-08-24 01:34:51",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-16 22:50:22",
            "updated_at": "2023-08-16 15:50:22"
        },]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
