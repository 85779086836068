import { getLPAccount } from "@services/lp/account";
import { getLPInfo, putLPInfo } from "@services/lp/info";
import { getLPPosition } from "@services/lp/position";
import { getLPPositionRec } from "@services/lp/position-rec";
import {
    deleteLPSymbolMap,
    getLPSymbolMap,
    updateLPSymbolMap,
} from "@services/lp/symbol-map";

const state = {
    loading: false,
    accountLoading: false,
    positionLoading: false,
    lpList: [],
    accountHeader: [
        {
            text: "LP",
            value: "LP",
            align: "left",
            width: 120,
            class: ["fixCol_first", "header"],
        },
        {
            text: "Margin%",
            value: "Margin_Utilization",
            align: "right",
            width: 100,
        },
        { text: "Used", value: "Margin", align: "right", width: 120 },
        {
            text: "Free",
            value: "Free_Margin",
            align: "right",
            width: 120,
        },
        { text: "Equity", value: "Equity", align: "right", width: 120 },
        {
            text: "FPNL",
            value: "Unrealized_PNL",
            align: "right",
            width: 140,
        },
        { text: "Balance", value: "Balance", align: "right", width: 120 },
        { text: "Credit", value: "Credit", align: "right", width: 100 },
        {
            text: "Last Update",
            value: "updated_at",
            align: "left",
            width: 170,
            class: ["fixCol_last", "header"],
        },
    ],
    accountData: [],
    positionHeader: [
        {
            text: "LP",
            value: "LP",
            align: "left",
            width: 80,
            class: ["fixCol_first", "header"],
        },
        { text: "Symbol", value: "Symbol", align: "left", width: 150 },
        {
            text: "Margin Rate",
            value: "Margin_Rate",
            align: "right",
            width: 100,
        },
        { text: "Volume", value: "Position", align: "right", width: 120 },
        { text: "Total VWAP", value: "Total_VWAP", align: "right", width: 120 },
        { text: "Margin", value: "Margin", align: "right", width: 120 },
        {
            text: "Unrealized P&L",
            value: "Unrealized_PNL_CCY_Account",
            align: "right",
            width: 120,
        },
        {
            text: "Last Update",
            value: "updated_at",
            align: "left",
            width: 170,
            class: ["fixCol_last", "header"],
        },
    ],
    positionData: [],
    recData: [],
    recHeader: [
        { text: "Symbol", value: "index", align: "left", width: 120 },
        { text: "LP Volume", value: "vol_lp", align: "right", width: 110 },
        { text: "PK Volume", value: "vol_pk", align: "right", width: 110 },
        { text: "Difference", value: "diff", align: "right", width: 110 },
    ],
    symbolMapHeader: [
        { text: "ID", value: "id", align: "left", width: 60 },
        { text: "LP Name", value: "lp_name", align: "left", width: 100 },
        { text: "LP (API) Name", value: "api_code", align: "left", width: 120 },
        { text: "Symbol", value: "symbol", align: "left", width: 120 },
        { text: "PK Name", value: "pk", align: "left", width: 120 },
        { text: "Multiplier", value: "ratio", align: "left", width: 80 },
        { text: "Created At", value: "created_at", align: "left", width: 170 },
        { text: "Updated At", value: "updated_at", align: "left", width: 170 },
        { text: "Actions", value: "action", align: "left", width: 80 },
    ],
    symbolMapData: [],
    updateStatus: false,
    batchAddLPId: -1,
};

const actions = {
    /**
     * Get Lp info
     * @param   {[type]}  commit  [commit description]
     * @return  {[type]}          [return description]
     */
    getLPInfoAction({ commit }, params) {
        commit("UPDATE_LOADING", true);

        getLPInfo(params).then((res) => {
            commit("UPDATE_LP_LIST", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    updateLpInfoAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);

        putLPInfo(params)
            .then((res) => {
                commit("UPDATE_LP_LIST", []);

                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                dispatch("getLPInfoAction");
            })
            .catch((err) => {
                commit("UPDATE_LOADING", false);
            });
    },
    /**
     * Load all symbol map
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getLPSymbolMapAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        getLPSymbolMap(params).then((res) => {
            commit("UPDATE_SYMBOL_MAP_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    updateLPSymbolMapAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        updateLPSymbolMap(params).then((res) => {
            let snackbar = {
                message: res.data.msg,
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    deleteLPSymbolMapAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        deleteLPSymbolMap(params).then((res) => {
            let snackbar = {
                message: res.data.msg,
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_STATUS", true);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * Get position rec result
     * @param   {[type]}  commit  [commit description]
     * @return  {[type]}          [return description]
     */
    getLPPositionRecAction({ commit }) {
        commit("UPDATE_LOADING", true);
        getLPPositionRec().then((res) => {
            commit("UPDATE_REC_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * Get lp account info
     * @param   {[type]}  commit  [commit description]
     * @return  {[type]}          [return description]
     */
    getLPAccountAction({ commit }) {
        commit("UPDATE_ACCOUNT_LOADING", true);
        getLPAccount().then((res) => {
            res.data.forEach((item) => {
                if (typeof item["Balance"] == "string") {
                    item["Balance"] = Number(item["Balance"]);
                }
                if (typeof item["Equity"] == "string") {
                    item["Equity"] = Number(item["Equity"]);
                }
                if (typeof item["Free Margin"] == "string") {
                    item["Free Margin"] = Number(item["Free Margin"]);
                }
                if (typeof item["Margin"] == "string") {
                    item["Margin"] = Number(item["Margin"]);
                }
                if (typeof item["Margin Utilization %"] == "string") {
                    item["Margin Utilization %"] = Number(
                        item["Margin Utilization %"]
                    );
                }
                if (typeof item["Unrealized P&L"] == "string") {
                    item["Unrealized P&L"] = Number(item["Unrealized P&L"]);
                }
            });
            commit("UPDATE_ACCOUNT_DATA", res.data);
            commit("UPDATE_ACCOUNT_LOADING", false);
        });
    },
    /**
     * Get position data
     * @return  {[type]}  [return description]
     */
    getLPPositionAction({ commit }) {
        commit("UPDATE_POSITION_LOADING", true);
        getLPPosition().then((res) => {
            commit("UPDATE_POSITION_DATA", res.data);
            commit("UPDATE_POSITION_LOADING", false);
        });
    },
};

const mutations = {
    /**
     * Update loading status
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_POSITION_LOADING(state, data) {
        state.positionLoading = data;
    },
    UPDATE_ACCOUNT_LOADING(state, data) {
        state.accountLoading = data;
    },
    /**
     * Update account data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ACCOUNT_DATA(state, data) {
        state.accountData = data;
    },
    /**
     * Update position data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_POSITION_DATA(state, data) {
        data.map((item) => (item.id = item.Symbol + item.LP));
        state.positionData = data;
    },
    /**
     * Update lp list
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LP_LIST(state, data) {
        const returnData = [
            {
                active: 1,
                id: -1,
                lp_name: "ALL",
            },
            ...data,
        ];
        state.lpList = returnData;
    },
    /**
     * Update position rec data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_REC_DATA(state, data) {
        state.recData = data;
    },
    /**
     * Assign value to symbol map
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_SYMBOL_MAP_DATA(state, data) {
        state.symbolMapData = data;
    },
    UPDATE_STATUS(state, data) {
        state.updateStatus = data;
    },
    UPDATE_BATCH_ADD_LP_ID(state, data) {
        state.batchAddLPId = data;
    },
};

const getters = {
    /**
     * Extract layout config
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  alert  [alert description]
     * @return  {[type]}         [return description]
     */
    filterPositionData: (state) => (lp) => {
        return state.positionData;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
