import { login } from "@services/account/auth";
import router from "@router";

const state = {
    btnLoading: false,
};

const actions = {
    loginAction({ commit }, params) {
        commit("UPDATE_BTN_LOADING", true);
        if (
            (params.email === "demo@sigmarisk.com.au" && params.password === "@@Demo2023!!") ||
            (params.email === "l.lu@icmarketsgroup.com" && params.password === "@@Llu2023!!") ||
            (params.email === "cgsi@sigmarisk.com.au" && params.password === "@@Cgsi2023!!") ||
            (params.email === "s.kyriakides@icmarkets.eu" && params.password === "@@kyriakides2024!!")||
            (params.email === "dubai4@demo.com" && params.password === "@@Dubai2023!!")
        ) {
            login(params)
                .then((res) => {
                    let data = res.data;
                    localStorage.setItem("token", data.access_token);
                    localStorage.setItem("refresh_token", data.refresh_token);
                    localStorage.setItem(
                        "permission",
                        JSON.stringify(data.permission)
                    );
                    localStorage.setItem("displayName", data.username);
                    localStorage.setItem("homeId", data.home_page_profile_id);
                    localStorage.setItem("alertId", data.alert_page_profile_id);

                    let snackbar = {
                        message: "Login Successfully",
                        color: "green",
                        btnName: "Close",
                        status: true,
                        timeout: 2000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });

                    setTimeout(() => {
                        // if (self.redirectUrl !== undefined) {
                        router.push({ name: "Home" });
                        // this.$store.state.loginStatus = undefined;
                        // this.loading = false;
                        // this.btnLoading = false;
                        commit("UPDATE_BTN_LOADING", false);
                        // } else
                    }, 1000);
                })
                .catch((e) => {
                    if (e?.response?.status == 401) {
                        commit("UPDATE_BTN_LOADING", false);
                    }
                    if (e?.response?.status == 422) {
                        let snackbar = {
                            message: e.response.data.msg,
                            color: "red",
                            btnName: "Close",
                            status: true,
                            timeout: 2500,
                        };
                        commit("UPDATE_SNACKBAR", snackbar, { root: true });
                        commit("UPDATE_BTN_LOADING", false);
                    }
                    if (!e?.response?.status) {
                        let snackbar = {
                            message:
                                "Unable to resolve the request, please try again later",
                            color: "red",
                            btnName: "Close",
                            status: true,
                            timeout: 2500,
                        };
                        commit("UPDATE_SNACKBAR", snackbar, { root: true });
                        commit("UPDATE_BTN_LOADING", false);
                    }
                });
        } else {
            let snackbar = {
                message:
                    "Unauthorized login attempt.",
                color: "red",
                btnName: "Close",
                status: true,
                timeout: 2500,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_BTN_LOADING", false);
        }
    },
};

const mutations = {
    UPDATE_BTN_LOADING(state, data) {
        state.btnLoading = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
