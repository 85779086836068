import { getTransaction } from "@services/search/transaction";

const state = {
    csvHeader: {
        login: "Login",
        book: "Book",
        amount: "Amount",
        group: "Group"
    },
    filterOrderBy: [
        "login",
        "book",
        "deposit",
        "task_deposit",
        "withdraw",
        "task_withdraw",
        "group"
    ],
    headers: [
        { text: "Login", value: "login" , align: "left", width: 100},
        { text: "Book", value: "book" , align: "left", width: 120},
        { text: "Deposit", value: "deposit" , align: "right", width: 120},
        { text: "Task Deposit", value: "task_deposit" , align: "right", width: 150},
        { text: "Withdraw", value: "withdraw" , align: "right", width: 120},
        { text: "Task Withdraw", value: "task_withdraw" , align: "right", width: 150},
        { text: "Group", value: "group", align: "left", width: 100 }
    ],
    filterShowResult: [100, 200, 400, 600, 1000, 2000],
    loading: false,
    tradesList: []
};

const actions = {
    getTransactionAction({ commit, state }, params) {
        console.log(params)

        commit("UPDATE_LOADING", true);
        commit("UPDATE_TRANSACTION_HISTORY", []);
        getTransaction(params).then(res => {
            const data = res.data;
            console.log(data);

            commit("UPDATE_TRANSACTION_HISTORY", data);
            commit("UPDATE_LOADING", false);
        });
    }
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_TRANSACTION_HISTORY(state, data) {
        state.tradesList = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
