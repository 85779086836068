<template>
    <div class="custom-menu">
        <v-menu
            offset-y
            v-for="(item, index) in items"
            :key="index"
            v-if="item.active == 1"
            open-on-hover
            :close-on-content-click="false"
            class="custom-menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="default"
                    v-on:click="redirect(item)"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="border-radius: 0"
                >
                    {{ item.text }}
                </v-btn>
            </template>
            <v-list
                v-if="item.children"
                class="pl-0 pr-0 pt-0 pb-0"
                style="text-align: left"
            >
                <v-btn
                    block
                    v-on:click="redirect(submenu)"
                    v-if="submenu.active == 1"
                    style="border-radius: 0px"
                    v-for="(submenu, subindex) in item.children"
                    :key="subindex"
                >
                    {{ submenu.text }}
                </v-btn>
            </v-list>
        </v-menu>
        <HomeProfile v-if="this.$route.name === 'Home'" />
        <AlertProfile v-if="this.$route.name === 'AlertOverviewV2'" />
    </div>
</template>

<script>
import AlertProfile from "../views/alert/ComponentAlertPageProfile.vue";
import HomeProfile from "../views/home/ComponentHomePageProfile.vue";

export default {
    name: "dataMenu",
    components: {
        HomeProfile,
        AlertProfile,
    },
    data() {
        return {
            currentRoute: [],
            items: [
                {
                    icon: "mdi-book-multiple",
                    text: "Book Monitor",
                    name: "book",
                    type: "link",
                    ref: "/",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "LP",
                    name: "lp",
                    type: "link",
                    ref: "/lp",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "Alert",
                    name: "alert",
                    type: "link",
                    ref: "/alert/v2",
                    active: 0,
                },
                {
                    icon: "mdi-chevron-down",
                    text: "Search",
                    name: "search",
                    type: "link",
                    ref: "/search",
                    active: 0,
                },
                {
                    icon: "mdi-chevron-down",
                    text: "Grafana",
                    name: "k8s",
                    type: "link",
                    ref: (document.querySelector("body").getAttribute("socketapi")||process.env.VUE_APP_SOCKET_API)+"/grafana",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "Copy Trade",
                    name: "copyTrade",
                    type: "link",
                    ref: "/copy-trade",
                    active: 0,
                },
            ],
        };
    },
    computed: {
        currentPath() {
            return localStorage.getItem("currentPath");
        },
    },
    watch: {
        $route: {
            handler() {
                // this.currentRoute = this.$route.meta.bread;
                // this.bread = []
                // if(this.$route && this.$route.meta && this.$route.meta.breadID){
                // 	let arr = this.$route.meta.breadID.split('-')
                // 	this.getBread(arr,this.breadNavList)
                // } else {
                // 	console.error('字段`this.$route.meta.breadID`没有设置')
                // }
            },
        },
    },
    methods: {
        redirect(data) {
            if (data.children) return;
            console.log(data.ref);
            if (data.name === "k8s") {
                window.open(data.ref, "_blank");
            } else {
                this.$router.push(data.ref).catch(() => {});
            }
        },
    },
    mounted() {
        this.currentRoute = this.$route.meta.bread;

        const data = JSON.parse(
            localStorage.getItem("permission")
        ).frontPermission;

        for (const item of this.items) {
            const name = item.name;
            if (data[name]) {
                item.active = 1;
                if (data[name].child.length > 0) {
                    const children = data[name].child;
                    if (item.children) {
                        for (const child of item.children) {
                            if (children.includes(child.name)) {
                                child.active = 1;
                            }
                        }
                    }
                }
            }
        }
    },
};
</script>
<style>
.v-list .v-list-item:hover {
    background: #0091da;
    cursor: pointer;
}
</style>
