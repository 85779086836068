
export function getDataFastTrade(query) {
    const mockData = {
        status: 200,
        data: [ {
            "server_id": 1,
            "login": "xxxxxx58",
            "volume": 10,
            "symbol": "BTCUSD",
            "books": "[B]",
            "order": "yyyyyy89",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 00:36:02",
            "close_time": "2023-08-24 00:36:46",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 00:36:46",
            "duration": 44
        },
        {
            "server_id": 1,
            "login": "xxxxxx58",
            "volume": 10,
            "symbol": "BTCUSD",
            "books": "[B]",
            "order": "yyyyyy07",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 00:49:03",
            "close_time": "2023-08-24 00:49:28",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 00:49:28",
            "duration": 25
        },
        {
            "server_id": 1,
            "login": "xxxxxx58",
            "volume": 10,
            "symbol": "BTCUSD",
            "books": "[B]",
            "order": "yyyyyy11",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 00:51:08",
            "close_time": "2023-08-24 00:51:40",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 00:51:40",
            "duration": 32
        },
        {
            "server_id": 1,
            "login": "xxxxxx58",
            "volume": 10,
            "symbol": "BTCUSD",
            "books": "[B]",
            "order": "yyyyyy15",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 00:53:39",
            "close_time": "2023-08-24 00:54:59",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 00:54:59",
            "duration": 80
        },
        {
            "server_id": 1,
            "login": "xxxxxx58",
            "volume": 10,
            "symbol": "BTCUSD",
            "books": "[B]",
            "order": "yyyyyy19",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 00:56:25",
            "close_time": "2023-08-24 00:57:03",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 00:57:03",
            "duration": 38
        },
        {
            "server_id": 1,
            "login": "xxxxxx83",
            "volume": 1,
            "symbol": "XAUUSD",
            "books": "[B]",
            "order": "yyyyyy33",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 01:02:18",
            "close_time": "2023-08-24 01:02:21",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 01:02:21",
            "duration": 3
        },
        {
            "server_id": 2,
            "login": "xxxxxx14",
            "volume": 1,
            "symbol": "VIXIndex",
            "books": "[Test]",
            "order": "yyyyyy83",
            "direction": "LONG",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 01:03:56",
            "close_time": "2023-08-24 01:05:06",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-24 01:05:06",
            "duration": 70
        },
        {
            "server_id": 2,
            "login": "xxxxxx15",
            "volume": 1.94,
            "symbol": "NAS100",
            "books": "[B]",
            "order": "yyyyyy23",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 01:06:42",
            "close_time": "2023-08-24 01:07:04",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-24 01:07:04",
            "duration": 22
        },
        {
            "server_id": 2,
            "login": "xxxxxx15",
            "volume": 1.94,
            "symbol": "NAS100",
            "books": "[S]",
            "order": "yyyyyy29",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 01:07:06",
            "close_time": "2023-08-24 01:07:19",
            "server_name": "MT4-Demo",
            "trigger_time": "2023-08-24 01:07:19",
            "duration": 13
        },
        {
            "server_id": 1,
            "login": "xxxxxx02",
            "volume": 30,
            "symbol": "XRPUSD",
            "books": "[S]",
            "order": "yyyyyy42",
            "direction": "SHORT",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 01:08:51",
            "close_time": "2023-08-24 01:09:05",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 01:09:05",
            "duration": 14
        },
        {
            "server_id": 1,
            "login": "xxxxxx64",
            "volume": 1,
            "symbol": "GBPJPY",
            "books": "[S]",
            "order": "yyyyyy19",
            "direction": "LONG",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 02:17:23",
            "close_time": "2023-08-24 02:19:23",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 02:19:23",
            "duration": 120
        },
        {
            "server_id": 1,
            "login": "xxxxxx81",
            "volume": 1.2,
            "symbol": "GBPJPY",
            "books": "[B]",
            "order": "yyyyyy42",
            "direction": "LONG",
            "threshold_volume": 1,
            "threshold_time": 120,
            "open_time": "2023-08-24 03:00:58",
            "close_time": "2023-08-24 03:01:17",
            "server_name": "MT5-Demo",
            "trigger_time": "2023-08-24 03:01:17",
            "duration": 19
        },]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
