
export function getTradeOnCredit(query) {
    const mockData = {
        status: 200,
        data: [{
            "server_id": 2,
            "server_name": "MT4-Demo",
            "login": "xxxxxx90",
            "equity_credit_ratio_threshold": 1,
            "books": "[B]",
            "credit": 120,
            "equity": 117.2,
            "balance": 32.82,
            "trigger_time": "2023-08-24 05:35:57",
            "group_name": "system"
        }]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

