
export function getDataWatchList(query) {
    const mockData = {
        status: 200,
        data: [
            {
                "login": "xxxxxx99",
                "server_id": 1,
                "symbol": "XAUUSD",
                "order": "yyyyy61",
                "books": "[B]",
                "direction": "LONG",
                "volume": 8,
                "open_time": "2023-08-08 09:16:44",
                "close_time": "2023-08-08 09:17:56",
                "open_price": 1934.17940692089,
                "close_price": 1934.1525,
                "server_name": "MT5-Demo",
                "trigger_time": "2023-08-08 09:16:44"
            },
            {
                "login": "xxxxxx99",
                "server_id": 1,
                "symbol": "XAUUSD",
                "order": "yyyyy67",
                "books": "[B]",
                "direction": "SHORT",
                "volume": 8,
                "open_time": "2023-08-08 09:18:08",
                "close_time": "2023-08-08 09:18:09",
                "open_price": 1934.28,
                "close_price": 1933.77,
                "server_name": "MT5-Demo",
                "trigger_time": "2023-08-08 09:18:08"
            },
            {
                "login": "xxxxxx99",
                "server_id": 1,
                "symbol": "XAUUSD",
                "order": "yyyyy66",
                "books": "[B]",
                "direction": "LONG",
                "volume": 8,
                "open_time": "2023-08-08 09:18:06",
                "close_time": "2023-08-08 09:18:08",
                "open_price": 1933.8,
                "close_price": 1934.28,
                "server_name": "MT5-Demo",
                "trigger_time": "2023-08-08 09:18:06"
            },
            {
                "login": "xxxxxx99",
                "server_id": 2,
                "symbol": "XAUUSD",
                "order": "yyyyy70",
                "books": "[B]",
                "direction": "LONG",
                "volume": 8,
                "open_time": "2023-08-08 09:18:13",
                "close_time": "2023-08-08 09:18:16",
                "open_price": 1933.78,
                "close_price": 1934.21,
                "server_name": "MT4-Demo",
                "trigger_time": "2023-08-08 09:18:13"
            },
        ]
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
