<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                color="primary"
                dark
                v-bind="attrs"
                x-small
                v-on="on"
                :disabled="isBtnDisabled"
                style="display: block; float: right"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="white"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 5px"
                            x-small
                        >
                            mdi-information
                        </v-icon>
                    </template>
                    <span>Customize home book list.</span>
                </v-tooltip>
                {{ selectedProfile.text }}
            </v-btn>
        </template>
        <v-list v-model="menu" dense>
            <div
                v-for="(item, index) in allProfile"
                :key="index"
                v-show="allProfile[0].id != 0"
            >
                <v-list-item>
                    <v-list-item-content
                        @click="changeSelectedProfile(item.text)"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
            </div>
            <v-dialog v-model="editingDialog" width="800" :persistent="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                        dense
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-show="allProfile[0].id != 0"
                        @click="assignEditingProfile"
                    >
                        <v-list-item-icon style="margin-right: 0">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                        dense
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="assignAddingProfile"
                    >
                        <v-list-item-icon style="margin-right: 0">
                            <v-icon small>mdi-plus-thick</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Add</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <v-card :loading="dialogLoading" id="editDialog">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">{{
                            isEditing ? "Edit Profile" : "Add Profile"
                        }}</v-toolbar-title>
                    </v-app-bar>

                    <v-card-text style="padding-top: 20px">
                        <v-row>
                            <v-col v-if="isEditing" cols="6"
                                ><v-select
                                    dense
                                    v-model="editingProfile"
                                    :items="allProfile"
                                    item-text="text"
                                    item-value="id"
                                    label="Profile name"
                                ></v-select
                            ></v-col>
                            <v-col cols="6"
                                ><v-text-field
                                    dense
                                    label="New name"
                                    :placeholder="dialogNamePlaceholder"
                                    v-model="newName"
                                ></v-text-field
                            ></v-col>
                        </v-row>
                        <v-alert dense outlined type="info">
                            Toggle the button to show or hide books displaying
                            on home page.
                        </v-alert>

                        <v-row>
                            <v-col
                                cols="2"
                                v-for="book in allBooks"
                                :key="book.name"
                                style="padding-top: 0"
                            >
                                <v-switch
                                    v-model="book.showing"
                                    color="primary"
                                    :label="book.name"
                                    dense
                                    hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="grey"
                            text
                            @click="UPDATE_EDITING_DIALOG(false)"
                        >
                            Cancel
                        </v-btn>

                        <template>
                            <v-menu
                                v-model="confirmMenu"
                                absolute
                                :position-x="x"
                                :position-y="y"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-show="isEditing"
                                        v-on="on"
                                        color="red"
                                        text
                                        @click="onDelete"
                                        :loading="dialogLoading"
                                    >
                                        Delete
                                    </v-btn>
                                </template>

                                <v-card width="450">
                                    <v-card-title style="color: orange">
                                        Are you sure you want to delete this
                                        profile?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            @click="confirmMenu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="confirmDeletion"
                                        >
                                            Confirm
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>

                        <v-btn
                            color="green"
                            text
                            @click="updateProfile"
                            :loading="dialogLoading"
                            style="margin-left: 0"
                        >
                            {{ isEditing ? "Update" : "Add" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-list>
    </v-menu>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import validator from "@assets/js/validator";

export default {
    mixins: [snackbar],
    computed: {
        ...mapState("Home", [
            "allProfile",
            "selectedProfile",
            "allBookOptions",
            "editingDialog",
            "updated",
            "isBtnDisabled",
            "dialogLoading",
        ]),
        dialogNamePlaceholder() {
            return this.isEditing
                ? "Enter a new name if needed."
                : "Please enter a profile name";
        },
    },
    data() {
        return {
            bookOptions: [],
            menu: false,
            isEditing: true,
            allBooks: null,
            editingProfile: null,
            newName: null,
            confirmMenu: false,
            x: 1200,
            y: 300,
        };
    },
    methods: {
        ...mapActions("Home", [
            "getBooksPkAction",
            "getProfileAction",
            "putProfileAction",
            "postProfileAction",
            "deletePageProfileAction",
        ]),
        ...mapMutations("Home", [
            "UPDATE_SELECTED_PROFILE",
            "UPDATE_ALL_PROFILE",
            "UPDATE_EDITING_DIALOG",
            "UPDATE_ADDING_DIALOG",
            "UPDATE_ALL_BOOK_OPTIONS",
            "CHANGE_UPDATE_STATUS",
            "UPDATE_BTN_STATUS",
            "UPDATE_BOOKS",
        ]),
        changeSelectedProfile(profileName) {
            this.UPDATE_SELECTED_PROFILE(
                this.allProfile.filter(
                    (profile) => profile.text == profileName
                )[0]
            );
        },
        onDelete() {
            this.confirmMenu = false;
            this.x =
                document.getElementById("editDialog").getBoundingClientRect()
                    .x + 175;
            this.y =
                document.getElementById("editDialog").getBoundingClientRect()
                    .y + 125;
            this.$nextTick(() => {
                this.confirmMenu = true;
            });
        },
        assignEditingProfile() {
            this.newName = null;
            this.isEditing = true;
            this.editingProfile = null;
            this.editingProfile = this.selectedProfile;
            this.UPDATE_EDITING_DIALOG(true);
        },
        assignAddingProfile() {
            this.newName = null;
            this.isEditing = false;
            this.allBooks.map((item) => {
                item.showing = true;
            });
            this.UPDATE_EDITING_DIALOG(true);
        },
        updateProfile() {
            const params = { value: { unselected_books: [] } };
            let unselectedBooks = [];
            this.allBooks.map((book) => {
                if (book.showing == false) {
                    unselectedBooks.push(book.name);
                }
            });
            params.value.unselected_books = unselectedBooks;
            if (params.value.unselected_books.length === this.allBooks.length) {
                this.snackBarDanger("Select at least one book");
                return
            }
            //editing
            if (this.isEditing) {
                params.id = this.editingProfile.id;

                if (
                    this.newName == null ||
                    this.newName == this.editingProfile.text ||
                    this.newName == ""
                ) {
                    params.profile_name = this.editingProfile.text;
                } else {
                    if (
                        this.allProfile.filter(
                            (profile) => profile.text == this.newName
                        ).length != 0 ||
                        this.newName.trim() == ""
                    ) {
                        this.snackBarDanger(
                            "The new profile name is invalid, please use another one."
                        );
                        return;
                    } else {
                        params.profile_name = this.newName;
                    }
                }

                this.putProfileAction(params);
            }
            //creating
            else {
                params.meta_value = "home";
                if (!validator.commonStringValidator(this.newName)) {
                    this.snackBarDanger(
                        "The profile name is invalid, please enter a new name."
                    );
                    return;
                } else if (
                    this.allProfile.filter(
                        (profile) => profile.text == this.newName
                    ).length != 0
                ) {
                    this.snackBarDanger(
                        "The profile name already exist, please enter a new name."
                    );
                    return;
                } else {
                    params.profile_name = validator.cleanUpStringData(
                        this.newName
                    );
                }

                this.postProfileAction(params);
            }
        },
        confirmDeletion() {
            const params = { id: this.editingProfile.id };
            this.deletePageProfileAction(params);
        },
    },
    watch: {
        selectedProfile(nv) {
            this.bookOptions.map((item) => {
                item.showing =
                    !nv.books.includes(item.name) &&
                    !nv.books.includes(item.parent);
            });
            this.allBooks = this.bookOptions;
        },
        editingProfile(nv) {
            if (nv != null) {
                if (this.allBooks) {
                    let newValue = null;
                    if (typeof nv === "number") {
                        newValue = this.allProfile.filter(
                            (profile) => profile.id == nv
                        )[0];
                        this.editingProfile = newValue;
                    } else {
                        newValue = nv;
                    }
                    this.allBooks.map((item) => {
                        if (item.name === "Hedge") {
                            item.showing === true;
                        } else {
                            item.showing =
                                !newValue.books.includes(item.name) &&
                                !newValue.books.includes(item.parent);
                        }
                    });
                }
            }
        },
        allBookOptions(nv, ov) {
            if (JSON.stringify(nv) != JSON.stringify(ov)) {
                const profileList = localStorage.getItem("homeProfiles");
                if (!!profileList) {
                    this.UPDATE_BTN_STATUS(true);
                    const recordedAll = JSON.parse(profileList);
                    this.UPDATE_ALL_PROFILE(recordedAll);
                    const alertId = parseInt(localStorage.getItem("homeId"));
                    if (alertId) {
                        if (recordedAll.find((item) => item.id === alertId)) {
                            this.UPDATE_SELECTED_PROFILE(
                                recordedAll.find((item) => item.id === alertId)
                            );
                        } else {
                            this.UPDATE_SELECTED_PROFILE(recordedAll[0]);
                        }
                    } else {
                        this.UPDATE_SELECTED_PROFILE(recordedAll[0]);
                    }
                } else {
                    this.getProfileAction({ meta_value: "home" });
                }

                this.bookOptions = [];
                nv.map((item) => {
                    if (item.children) {
                        this.bookOptions.push({
                            id: item.book_id,
                            name: item.book_name,
                            parent: null,
                            showing: true,
                        });
                        item.children.map((child) => {
                            this.bookOptions.push({
                                id: child.book_id,
                                name: child.book_name,
                                parent: item.book_name,
                                showing: true,
                            });
                        });
                    } else {
                        this.bookOptions.push({
                            id: item.book_id,
                            name: item.book_name,
                            parent: null,
                            showing: true,
                        });
                    }
                });
            }
        },
        updated(nv, ov) {
            if (nv) {
                this.getProfileAction({ meta_value: "home" });
                this.CHANGE_UPDATE_STATUS(false);
            }
        },
    },
    mounted() {
        this.UPDATE_ALL_BOOK_OPTIONS([]);
        this.CHANGE_UPDATE_STATUS(false);
    },
};
</script>
