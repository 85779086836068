import common from "@assets/js/common";

const state = {
    loading: false,
    monitorData: [
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: 0.7,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692939989,
          user: "Demo:MT5-Demo:xxxxx39",
          login: "MT5-Demo:xxxxx39",
          trigger_time: "2023-08-25 15:06:29",
        },
        {
          books: ["S"],
          broker: "Demo",
          exposureVolume: 2,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692940001,
          user: "Demo:MT5-Demo:xxxxx656",
          login: "MT5-Demo:xxxxx56",
          trigger_time: "2023-08-25 15:06:41",
        },
        {
          books: ["Test"],
          broker: "Demo",
          exposureVolume: 0.52,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "EURUSD",
          timestamp: 1692939889,
          user: "Demo:MT5-Demo:xxxxx614",
          login: "MT5-Demo:xxxxx14",
          trigger_time: "2023-08-25 15:04:49",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: 5,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692939964,
          user: "Demo:MT4-Demo:xxxxx2742",
          login: "MT4-Demo:xxxxx42",
          trigger_time: "2023-08-25 15:06:04",
        },
        {
          books: ["S"],
          broker: "Demo",
          exposureVolume: -5,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692939985,
          user: "Demo:MT5-Demo:xxxxx01",
          login: "MT5-Demo:xxxxx01",
          trigger_time: "2023-08-25 15:06:25",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: -2.06,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692940001,
          user: "Demo:MT5-Demo:xxxxx088",
          login: "MT5-Demo:xxxxx88",
          trigger_time: "2023-08-25 15:06:41",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: 1.03,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "NSDCAD",
          timestamp: 1692939982,
          user: "Demo:MT5-Demo:xxxxx555",
          login: "MT5-Demo:xxxxx55",
          trigger_time: "2023-08-25 15:06:22",
        },
        {
          books: ["S"],
          broker: "Demo",
          exposureVolume: 6.5,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "F40",
          timestamp: 1692939945,
          user: "Demo:MT5-Demo:xxxxx300",
          login: "MT5-Demo:xxxxx00",
          trigger_time: "2023-08-25 15:05:45",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: 0.9,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "EURUSD",
          timestamp: 1692939987,
          user: "Demo:MT5-Demo:xxxxx36",
          login: "MT5-Demo:xxxxx36",
          trigger_time: "2023-08-25 15:06:27",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: -1.5,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692939791,
          user: "Demo:MT5-Demo:xxxxx170",
          login: "MT5-Demo:xxxxx70",
          trigger_time: "2023-08-25 15:03:11",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: -0.5,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "XAUUSD",
          timestamp: 1692939964,
          user: "Demo:MT4-Demo:xxxxx0015",
          login: "MT4-Demo:xxxxx15",
          trigger_time: "2023-08-25 15:06:04",
        },
        {
          books: ["B"],
          broker: "Demo",
          exposureVolume: 1.43,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "NSDUSD",
          timestamp: 1692939975,
          user: "Demo:MT5-Demo:xxxxx499",
          login: "MT5-Demo:xxxxx99",
          trigger_time: "2023-08-25 15:06:15",
        },
        {
          books: ["S"],
          broker: "Demo",
          exposureVolume: -2,
          exposureVolumeThreshold: 0.5,
          force: false,
          messageType: "MSG_TYPE_MONITOR_LARGE_EXPOSURE_VOLUME",
          priority: 1,
          symbol: "HK50",
          timestamp: 1692939987,
          user: "Demo:MT5-Demo:xxxxx709",
          login: "MT5-Demo:xxxxx09",
          trigger_time: "2023-08-25 15:06:27",
        },
      ],
    monitorHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Volume", value: "exposureVolume", align: "left" },
    ],
    csvHeader: {
        Login: "login",
        Books: "books",
        Symbol: "symbol",
        Volume: "exposureVolume",
    },
};

const actions = {
    /**
     * Process alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     *
     * @return  {[type]}          [return description]
     */
    processLargeExposureVolume({ commit }, data) {
        let result = [];
        for (let item in data) {
            let temp = data[item];
            temp["login"] = item.split(":")[1]+":"+item.split(":")[2];
            temp["symbol"] = item.split(":")[3];
            temp["trigger_time"] = common.convertUnixtimeToDatetimeString(
                temp.timestamp
            );
            if (temp["books"].length == 2 && temp["books"].includes("STOCKS")) {
                temp["books"] = temp["books"].filter((i) => i !== "STOCKS");
            } else if (temp["books"].length >= 3) {
                temp["books"] = temp["books"].filter(
                    (i) =>
                        i !== "STOCKS" && i !== "EquityAU" && i !== "EquityUS"
                );
            }
            result.push(temp);
        }
        // commit("UPDATE_MONITOR_DATA", result);
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_MONITOR_HEADER(state, data) {
        state.monitorHeader = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
