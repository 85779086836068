const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 35,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937452,
            "user": "Demo:MT5-Demo:xxxxxx42"
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 10.7,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx14"
        },
        {
            "books": "Test",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 1.48,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "USDJPY",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx99"
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 2.5,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "DJ30",
            "timestamp": 1692937452,
            "user": "Demo:MT5-Demo:xxxxxx46"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 1.2,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx29"
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 2,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "USDJPY",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx15"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 9,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "DJ30",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx27"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 1,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937452,
            "user": "Demo:MT5-Demo:xxxxxx89"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 5.8,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937452,
            "user": "Demo:MT5-Demo:xxxxxx95"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 4,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx15"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 3.5000000000000018,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "GBPUSD",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx02"
        },
        {
            "books": "S",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 8.5,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "XAUUSD",
            "timestamp": 1692937452,
            "user": "Demo:MT5-Demo:xxxxxx49"
        },
        {
            "books": "B",
            "broker": "Demo",
            "force": false,
            "lockingVolume": 1.52,
            "lockingVolumeThreshold": 1,
            "messageType": "MSG_TYPE_MONITOR_LOCKING_POSITION",
            "priority": 1,
            "symbol": "NZDUSD",
            "timestamp": 1692937454,
            "user": "Demo:MT5-Demo:xxxxxx76"
        },
    ],
    alertHeader: [
        { text: "Login", value: "user", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Locking Pos",
            value: "lockingVolume",
            align: "right",
        },
    ],
    csvHeader: {
        Login: "user",
        Symbol: "symbol",
        Books: "books",
        "Locking Position": "lockingVolume",
        "Locking Position Threshold": "lockingVolumeThreshold",
    },
    selectedDate: null,
};

const actions = {
    processLockingPosition({ commit }, data) {
        let result = [];
        for (let item in data) {
            let temp = data[item];
            temp.books = temp.books.join(",");

            result.push(temp);
        }

        // commit("UPDATE_ALERT_DATA", result);
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
