import dayjs from "dayjs";
import { mapState } from "vuex";

export const helper = {
    computed: {
        ...mapState(["brokerMap"]),
    },
    methods: {
        /**
         * Fetch broker's book list
         * @param  {[type]} brokerId [description]
         * @return {[type]}          [description]
         */
        getBookListByBrokerId(brokerId) {
            const bookMap = JSON.parse(localStorage.getItem("bm"));
            const result = bookMap.filter((item) => item.broker_id == brokerId);
            return result;
        },

        /**
         * Trim Symbol
         * @param {*} room
         */
        trimSymbol(symbol, rules) {
            for (let i = 0; i < rules.length; i++) {
                if (symbol.indexOf(rules[i]) != -1) {
                    return symbol.split(rules[i])[0];
                }
            }
            return symbol;
        },
        /**
         * check form's field is valid or not
         * Eg: empty or null is invalid
         * @param {*} ts
         */
        formatDate(ts) {
            const current = new Date(ts);

            const result = "";
            let month = "" + (current.getMonth() + 1);
            let day = "" + current.getDate();
            const year = current.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        /**
         * check form's field is valid or not
         * Eg: empty or null is invalid
         * @param {*} ts
         */
        formatTimestamp(ts) {
            const current = new Date(ts);

            let result = "";
            let month = "" + (current.getMonth() + 1);
            let day = "" + current.getDate();
            const year = current.getFullYear();

            const hour =
                current.getHours() < 10
                    ? "0" + current.getHours()
                    : current.getHours();
            const minutes =
                current.getMinutes() < 10
                    ? "0" + current.getMinutes()
                    : current.getMinutes();
            const sec =
                current.getSeconds() < 10
                    ? "0" + current.getSeconds()
                    : current.getSeconds();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            result = [year, month, day].join("-");
            result += " ";
            result += hour + ":" + minutes + ":" + sec;

            return result;
        },
        /**
         * Check if user visit this site via mobile agent or not
         * @return  {[type]}  [return description]
         */
        isMobile() {
            if (
                navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)
            ) {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Check if data is valid json or not
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        isValidJson(data) {
            try {
                if (typeof data === "object" && data !== null) {
                    return true;
                } else {
                    JSON.parse(data);
                }
            } catch (e) {
                return false;
            }

            return true;
        },

        /**
         * Round particular number with decimal place
         * @param   {[type]}  data   [data description]
         * @param   {[type]}  place  [place description]
         * @return  {[type]}         [return description]
         */
        roundDecimal(data, place) {
            if (data == null || data == undefined) return data;
            if (isNaN(data)) return data;
            return data.toFixed(place);
        },
        /**
         * Compare two value
         *
         * @param oldvalue type string/int
         * @param	newvalue type string/int
         *
         * @retrun number -1/0/1 represents less/same/bigger
         */
        compareUpdatedValue(ov, nv) {
            if (typeof ov !== typeof nv) {
                console.log("comparing values are different types");
                return undefined;
            } else {
                if (typeof nv === "string") {
                    return nv.localeCompare(ov);
                } else {
                    if (nv > ov) {
                        return 1;
                    } else if (nv < ov) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            }
        },

        getMT4Time() {
            let utc = require("dayjs/plugin/utc");
            let timezone = require("dayjs/plugin/timezone");
            dayjs.extend(utc);
            dayjs.extend(timezone);
            return dayjs()
                .tz("US/Eastern")
                .add(7, "hour")
                .format("DD/MM/YYYY, HH:mm:ss");
        },

        getMT4Int() {
            let utc = require("dayjs/plugin/utc");
            let timezone = require("dayjs/plugin/timezone");
            dayjs.extend(utc);
            dayjs.extend(timezone);
            return dayjs()
                .tz("US/Eastern")
                .add(7, "hour")/1000
        },

        numberWithCommas(x) {
            return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
