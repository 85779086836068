import { getRawHistory } from "@services/search/rawHistory";

const state = {
    filterOrderBy: [
        "login",
        "ticket",
        "symbol",
        "cmd",
        "volume",
        "open_time",
        "open_price",
        "close_time",
        "close_price",
        "profit",
        "sl",
        "tp",
        "swaps",
        "commission",
        "comment",
        "reason"
    ],
    filterReasonLists: [
        { value: 0, name: "CLIENT" },
        { value: 1, name: "EXPERT" },
        { value: 2, name: "DEALER" },
        { value: 3, name: "SL" },
        { value: 4, name: "TP" },
        { value: 5, name: "SO" },
        { value: 6, name: "ROLLOVER" },
        { value: 7, name: "EXTERNAL_CLIENT" },
        { value: 8, name: "VMARGIN" },
        { value: 9, name: "GATEWAY" },
        { value: 10, name: "SIGNAL" },
        { value: 11, name: "SETTLEMENT" },
        { value: 12, name: "TRANSFER" },
        { value: 13, name: "SYNC" },
        { value: 14, name: "EXTERNAL_SERVICE" },
        { value: 15, name: "MIGRATION" },
        { value: 16, name: "MOBILE" },
        { value: 17, name: "WEB" },
        { value: 18, name: "SPLIT" },

    ],
    filterCmdLists: [
        { name: "Buy", cmd: 0 },
        { name: "Sell", cmd: 1 }
    ],
    filterShowResult: [100, 200, 400, 600, 1000, 2000],
    csvHeader: {
        book: "Book",
        login: "Login",
        ticket: "Ticket",
        symbol: "Symbol",
        cmd: "Type",
        volume: "Volume",
        open_time: "OpenTime",
        open_price: "OpenPrice",
        close_time: "CloseTime",
        close_price: "ClosePrice",
        profit: "Profit",
        sl: "Sl",
        tp: "Tp",
        swaps: "Swaps",
        commission: "Commission",
        comment: "Comment",
        reason: "Reason"
    },
    headers: [
        { text: "Login", value: "login", align: "left", width: 100 },
        { text: "Ticket", value: "ticket", align: "left", width: 100 },
        { text: "Symbol", value: "symbol", align: "left", width: 120 },
        { text: "Type", value: "cmd", align: "left", width: 90 },
        { text: "Volume", value: "volume", align: "right", width: 120 },
        { text: "Open Price", value: "open_price", align: "right", width: 140 },
        { text: "Open Time", value: "open_time", align: "left", width: 170 },
        { text: "Close Price", value: "close_price", align: "right", width: 140 },
        { text: "Close Time", value: "close_time", align: "left", width: 170 },
        { text: "Profit", value: "profit", align: "right", width: 100 },
        { text: "SL", value: "sl", align: "right", width: 80 },
        { text: "TP", value: "tp", align: "right", width: 80 },
        { text: "Swaps", value: "swaps", align: "right", width: 100 },
        { text: "Commission", value: "commission", align: "right", width: 140 },
        { text: "Comment", value: "comment", align: "left", width: 120 },
        { text: "Reason", value: "reason", align: "left", width: 120 },
        { text: "Book A", value: "book_a_name", align: "left", width: 100 },
        { text: "A%", value: "percentage_a", align: "left", width: 80 },
        { text: "Book B", value: "book_b_name", align: "left", width: 100 },
        { text: "B%", value: "percentage_b", align: "left", width:80 }
    ],
    loading: false,
    btnLoading: false,
    tradesList: [],
    openTimeMin: 20,
    openTimeMax: 50,
    openTimeRange: [20, 50],
    openTimePeriod: [],
    closeTimeMin: 20,
    closeTimeMax: 50,
    closeTimeRange: [20, 50],
    closeTimePeriod: [],
};

const actions = {
    getRawHistoryAction({ commit,state }, params) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_BTN_LOADING", true);
        commit("UPDATE_HISTORY", []);
        getRawHistory(params).then((res) => {
            const data = res.data.map((item)=>{
                item.reason = state.filterReasonLists[item.reason].name
                item.cmd = state.filterCmdLists[item.cmd].name
                return item
            });

            commit("UPDATE_HISTORY", data);
            commit("UPDATE_TIME_FACTORS")
            commit("UPDATE_LOADING", false);
            commit("UPDATE_BTN_LOADING", false);
        })
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_BTN_LOADING(state, data) {
        state.btnLoading = data;
    },
    UPDATE_HISTORY(state, data) {
        state.tradesList = data;
    },
    UPDATE_TIME_FACTORS(state){
        for (const i of state.tradesList) {
            state.openTimePeriod.push(Date.parse(i.open_time));
            state.closeTimePeriod.push(Date.parse(i.close_time));
        }
        state.openTimeMin = Math.min.apply(Math, state.openTimePeriod);
        state.openTimeMax = Math.max.apply(Math, state.openTimePeriod);
        state.openTimeRange = [state.openTimeMin, state.openTimeMax];
        state.closeTimeMin = Math.min.apply(Math, state.closeTimePeriod);
        state.closeTimeMax = Math.max.apply(Math, state.closeTimePeriod);
        state.closeTimeRange = [state.closeTimeMin, state.closeTimeMax];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
