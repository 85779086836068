import {
    getConfig,
    updateConfig,
    createConfig,
    deleteConfig,
} from "@services/alert/config";
import { getServer } from "@services/server";
import { getSecurity } from "@services/security";
import { getSymbol } from "@services/symbol";
import { getOptions } from "@services/alert/config-options";
import store from "@store/store";

const state = {
    configData: [],
    updated: false,
    errorOccur: false,
    loading: true,
    tabName: "",
    serverOptions: [],
    securityOptions: [],
    symbolOptions: [],
    headerOptions: {
        "Fast Trade": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Duration Threshold",
                value: "duration_threshold",
            },
            {
                text: "Volume Threshold",
                value: "volume_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Large Exposure": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Exposure Threshold",
                value: "exposure_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Symbol Regx",
                value: "symbol_regx",
            },
            {
                text: "Group Regx",
                value: "group_regx",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Large Volume": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Symbol Regx",
                value: "symbol_regx",
            },
            {
                text: "Symbol Group",
                value: "symbol_group",
            },
            {
                text: "Volume Threshold",
                value: "volume_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Locking Position": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Lock Volume Threshold",
                value: "lock_volume_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Symbol Regx",
                value: "symbol_regx",
            },
            {
                text: "Symbol Group",
                value: "symbol_group",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        Mandate: [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Volume Threshold",
                value: "volume_threshold",
            },
            {
                text: "Profit Threshold",
                value: "profit_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Symbol Regx",
                value: "symbol_regx",
            },
            {
                text: "Symbol Group",
                value: "symbol_group",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Profit Taker": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Profit Threshold",
                value: "profit_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Symbol Regx",
                value: "symbol_regx",
            },
            {
                text: "Group Regx",
                value: "group_regx",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Watch List": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            // {
            //     text: "Priority",
            //     value: "priority",
            // },
            // {
            //     text: "Book Regx",
            //     value: "book_regx",
            // },
            // {
            //     text: "Group Regx",
            //     value: "group_regx",
            // },
            {
                text: "Login List",
                value: "login_list",
            },
            // {
            //     text: "Enable Notification",
            //     value: "enable_notification",
            // },
            // {
            //     text: "Notification Rule",
            //     value: "notification_rule",
            // },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Same Direction": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Volume Threshold",
                value: "volume_threshold",
            },
            {
                text: "Interval Threshold",
                value: "interval_threshold",
            },
            {
                text: "Number Threshold",
                value: "number_threshold",
            },
            {
                text: "Minimum Volume Threshold",
                value: "minimum_volume_threshold",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Trade On Credit": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Equity Credit Ratio Threshold",
                value: "equity_credit_ratio_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Book Regx",
                value: "book_regx",
            },
            {
                text: "Group Regx",
                value: "group_regx",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Daily Winner": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Close Profit Threshold",
                value: "close_profit_threshold",
            },
            {
                text: "Book",
                value: "books",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Daily Loser": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Close Profit Threshold",
                value: "close_profit_threshold",
            },
            {
                text: "Book",
                value: "books",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Large Exposure Volume": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Volume Threshold",
                value: "volume_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Symbol Regx",
                value: "symbol_regx",
            },
            {
                text: "Group Regx",
                value: "group_regx",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Deposit Withdrawal": [
            {
                text: "Id",
                value: "id",
            },
            // {
            //     text: "Broker Id",
            //     value: "broker_id",
            // },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Withdraw Deposit Threshold",
                value: "withdraw_deposit_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Book Regx",
                value: "book_regx",
            },
            {
                text: "Group Regx",
                value: "group_regx",
            },
            {
                text: "Login List",
                value: "login_list",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        Tick: [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "Server",
                value: "server_name",
            },
            {
                text: "Symbol",
                value: "symbol",
            },
            {
                text: "Security",
                value: "security",
            },
            {
                text: "Continuous Count",
                value: "continuous_count",
            },
            {
                text: "Seconds Threshold",
                value: "seconds_threshold",
            },

            {
                text: "Email Threshold",
                value: "email_threshold",
            },
            {
                text: "Telegram ID",
                value: "telegram_id",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Weighted Volume": [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "Server",
                value: "server_id",
            },
            {
                text: "Weighted Volume Threshold",
                value: "weighted_volume_threshold",
            },
            {
                text: "Priority",
                value: "priority",
            },
            {
                text: "Group Regx",
                value: "group_regx",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
    },
    csvFields: {
        "Fast Trade": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Duration Threshold": "duration_threshold",
            "Volume Threshold": "volume_threshold",
            Priority: "priority",
        },
        "Large Exposure": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Exposure Threshold": "exposure_threshold",
            Priority: "priority",
            "Symbol Regx": "symbol_regx",
            "Group Regx": "group_regx",
        },
        "Large Volume": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Symbol Regx": "symbol_regx",
            "Symbol Group": "symbol_group",
            "Volume Threshold": "volume_threshold",
            Priority: "priority",
        },
        "Locking Position": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Lock Volume Threshold": "lock_volume_threshold",
            Priority: "priority",
            "Symbol Regx": "symbol_regx",
            "Symbol Group": "symbol_group",
        },
        Mandate: {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Volume Threshold": "volume_threshold",
            "Profit Threshold": "profit_threshold",
            Priority: "priority",
            "Symbol Regx": "symbol_regx",
            "Group Regx": "symbol_group",
        },
        "Profit Taker": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Profit Threshold": "profit_threshold",
            Priority: "priority",
            "Symbol Regx": "symbol_regx",
            "Group Regx": "group_regx",
        },
        "Watch List": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            Priority: "priority",
            "Book Regx": "book_regx",
            "Group Regx": "group_regx",
            "Login List": "login_list",
            "Enable Notification": "enable_notification",
            "Notification Rule": "notification_rule",
        },
        "Same Direction": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            Priority: "priority",
            "Volume Threshold": "volume_threshold",
            "Interval Threshold": "interval_threshold",
            "Number Threshold": "number_threshold",
            "Minimum Volume Threshold": "minimum_volume_threshold",
        },
        "Trade On Credit": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Equity Credit Ratio Threshold": "equity_credit_ratio_threshold",
            Priority: "priority",
            "Book Regx": "book_regx",
            "Group Regx": "group_regx",
        },
        "Daily Winner": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Close Profit Threshold": "close_profit_threshold",
            Book: "books",
            Priority: "priority",
        },
        "Daily Loser": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Close Profit Threshold": "close_profit_threshold",
            Book: "books",
            Priority: "priority",
        },
        "Large Exposure Volume": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Volume Threshold": "volume_threshold",
            Priority: "priority",
            "Symbol Regx": "symbol_regx",
            "Group Regx": "group_regx",
        },
        "Deposit Withdrawal": {
            ID: "id",
            "Broker ID": "broker_id",
            Server: "server_id",
            "Withdrawal Deposit Threshold": "withdraw_deposit_threshold",
            Priority: "priority",
            "Book Regx": "book_regx",
            "Group Regx": "group_regx",
            "Login List": "login_list",
        },
        Tick: {
            ID: "id",
            Server: "server_id",
            "Server Name": "server_name",
            Symbol: "symbol",
            Security: "security",
            "Continuous Count": "continuous_count",
            "Seconds Threshold": "seconds_threshold",
            "Email Threshold": "email_threshold",
            "Telegram ID": "telegram_id",
        },
    },
    configDialog: false,
    selectedAlertConfig: null,
    options: {
        symbol_regx: [],
        group_regx: [],
        symbol_group: [],
        book_regx: [],
        filter_login: [],
    },
};

const actions = {
    /**
     * Fetch configuration data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getConfigDataAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        const typeName = data.toLowerCase().replaceAll(" ", "-");
        commit("CHANGE_TAB", typeName);
        getConfig(typeName).then((res) => {
            commit("GET_CONFIG_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    updateConfigDataAction({ commit }, data) {
        const typeName = data.type.toLowerCase().replaceAll(" ", "-");
        commit("CHANGE_UPDATE_STATUS", false);
        commit("UPDATE_LOADING", true);
        updateConfig(typeName, data).then((res) => {
            store.state.snackcolor = "green";
            store.state.snackbar = true;
            store.state.snackmsg = res.data.msg;
            commit("CHANGE_UPDATE_STATUS", true);
        });
    },
    createConfigDataAction({ commit }, data) {
        const typeName = data?.type.toLowerCase().replaceAll(" ", "-");
        commit("CHANGE_UPDATE_STATUS", false);
        commit("UPDATE_LOADING", true);
        createConfig(typeName, data)
            .then((res) => {
                store.state.snackcolor = "green";
                store.state.snackbar = true;
                store.state.snackmsg = res.data.msg;
                commit("CHANGE_UPDATE_STATUS", true);
            })
            .catch(({ response }) => {
                if (response.status == 409) {
                    let snackbar = {
                        message:
                            response.data.msg + ", please choose another name",
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                    commit("UPDATE_LOADING", false);
                    commit("CHANGE_ERROR_STATUS", true);
                }
            });
    },
    deleteConfigDataAction({ commit }, data) {
        const typeName = data.type.toLowerCase().replaceAll(" ", "-");
        commit("CHANGE_UPDATE_STATUS", false);
        commit("UPDATE_LOADING", true);
        deleteConfig(typeName, data).then((res) => {
            if (res.data.code !== 422) {
                store.state.snackcolor = "green";
                store.state.snackbar = true;
                store.state.snackmsg = res.data.msg;
                commit("CHANGE_UPDATE_STATUS", true);
            } else {
                commit("UPDATE_LOADING", false);
                commit("CHANGE_ERROR_STATUS", true);
            }
        });
    },
    getServerOptionsAction({ commit }) {
        commit("UPDATE_LOADING", true);
        getServer().then((res) => {
            const newServerOptions = res.data.map((item) => {
                return { name: item.server_name, value: item.id };
            });
            commit("UPDATE_SERVER_OPTIONS", newServerOptions);
            commit("UPDATE_LOADING", false);
        });
    },
    getSecurityOptionsAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getSecurity({ server_id: data.join(",") }).then((res) => {
            const serverReference = {};
            state.serverOptions.map(
                (item) => (serverReference[item.value] = item.name)
            );
            let newSecurityOptions = [];
            res.data.map((item) => {
                if (!!item.name) {
                    newSecurityOptions.push({
                        name:
                            item.name +
                            " (" +
                            serverReference[item.server_id] +
                            ")",
                        value: item.server_id + ":" + item.index,
                    });
                }
            });
            commit("UPDATE_SECURITY_OPTIONS", newSecurityOptions);
            commit("UPDATE_LOADING", false);
        });
    },
    getSymbolOptionsAction({ commit, state }, data) {
        commit("UPDATE_LOADING", true);
        getSymbol(data).then((res) => {
            let result = [];
            res.data.map((item) => {
                const found = state.configData.filter(
                    (i) =>
                        i.symbol == item.name && i.server_id === item.server_id
                );

                if (found.length == 0) {
                    result.push(item);
                }
            });
            const newSymbolOptions = result.map((item) => {
                return {
                    name: item.name,
                    value: item.name + "@" + item.server_id,
                };
            });
            commit("UPDATE_SYMBOL_OPTIONS", newSymbolOptions);
            commit("UPDATE_LOADING", false);
        });
    },
    getConfigForAlertAction({ commit, state }, selectedAlert) {
        state.selectedAlertConfig = selectedAlert;
        commit("UPDATE_CONFIG_DIALOG", true);
        commit("UPDATE_LOADING", true);
        const typeName = selectedAlert.toLowerCase().replaceAll(" ", "-");
        getConfig(typeName).then((res) => {
            commit("GET_CONFIG_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    getOptionsAction({ state }) {
        getOptions("symbol").then((res) => {
            state.options.symbol_regx = res.data || [];
            state.options.filter_symbol = res.data || [];
        });
        getOptions("group").then((res) => {
            state.options.group_regx = res.data || [];
            state.options.filter_group = res.data || [];
        });
        getOptions("security").then((res) => {
            state.options.symbol_group = res.data || [];
            state.options.filter_symbol_group = res.data || [];
        });
        getOptions("login").then((res) => {
            state.options.filter_login = res.data || [];
        });
    },
};

const mutations = {
    GET_CONFIG_DATA(state, data) {
        state.configData = data;
        state.isLoading = false;
    },
    CHANGE_UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_CONFIG_DIALOG(state, data) {
        state.configDialog = data;
    },
    CHANGE_ERROR_STATUS(state, data) {
        state.errorOccur = data;
    },
    CHANGE_TAB(state, data) {
        state.tabName = data;
    },
    UPDATE_SERVER_OPTIONS(state, data) {
        state.serverOptions = data;
    },
    UPDATE_SECURITY_OPTIONS(state, data) {
        state.securityOptions = data;
    },
    UPDATE_SYMBOL_OPTIONS(state, data) {
        state.symbolOptions = data;
    },
    UPDATE_BOOK_OPTIONS(state, data) {
        state.options["book_regx"] = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
